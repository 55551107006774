import React from "react"
import { v4 as uuidv4 } from "uuid"
import { useRequest } from "api/hooks"
import {
  DashboardItem,
  MainPageDataFilterType,
  SuperWinsTableColumnNames,
  SuperWinsZones,
  UseStaticDashboardApi
} from "./types"
import mainConstants, {
  superWinsColumnNames,
  zonesIconsDict
} from "./constants"
import { GamesData } from "store/dictionary/types"
import { formatDateDefault, formatToCurrency } from "constants/index"
import { Moment } from "moment"
import { Image } from "antd"

const socketDashboard = () => {
  const userFromStorage: string = localStorage.getItem(`user`) || ``
  const user: { token: string } | undefined = userFromStorage
    ? JSON.parse(userFromStorage)
    : userFromStorage

  return new WebSocket(
    `wss://${window.location.hostname}/websocket/dashboard?token=${
      user?.token
    }&uniqid=${uuidv4()}`
  )
}

export default socketDashboard

export const initialDashboardState: DashboardItem = {
  data: { value: 0, details: [] },
  pending: true,
  isVisible: false,
  rendered: false
}

export const initialDashboardStateHidden = {
  ...initialDashboardState,
  isVisible: false
}

export const useStaticDashboardApi = ({
  halls,
  gmt
}: UseStaticDashboardApi) => {
  const gmtAsNumber = Number(gmt)
  const { request: getSuperWinsData } = useRequest({
    url: `v1/superwins/history/wins/recent`,
    method: `POST`,
    requestBody: {
      filter: {
        halls,
        gmt: gmtAsNumber
      }
    }
  })
  return { getSuperWinsData }
}

export const makeSuperWinsModalColumns = (
  gamesDict: GamesData[],
  isModal?: boolean
) => {
  return [
    {
      title: () => (
        <div className={`MarginLeft${isModal ? "12" : "24"}`}>
          {superWinsColumnNames[SuperWinsTableColumnNames.hall_zone]}
        </div>
      ),
      dataIndex: SuperWinsTableColumnNames.hall_zone,
      key: SuperWinsTableColumnNames.hall_zone,
      width: 120,
      minWidth: 120,
      render: (zone: SuperWinsZones) => (
        <div
          className={`SuperWinsColumns_ZoneCell MarginLeft${
            isModal ? "12" : "24"
          }`}
        >
          <Image
            src={zonesIconsDict[zone]?.img}
            width={20}
            height={20}
            preview={false}
          />
          <div>{zonesIconsDict[zone]?.title}</div>
        </div>
      )
    },
    {
      title: superWinsColumnNames[SuperWinsTableColumnNames.game_id],
      dataIndex: SuperWinsTableColumnNames.game_id,
      key: SuperWinsTableColumnNames.game_id,
      width: 170,
      minWidth: 170,
      render: (gameId: number) => {
        const foundedGame = gamesDict.find(el => el.id === gameId)
        if (foundedGame) {
          return (
            <div className="SuperWinsColumns_GameCell">
              <Image
                src={foundedGame.image}
                width={20}
                height={20}
                preview={false}
              />
              <div>{foundedGame.name}</div>
            </div>
          )
        }
      }
    },
    {
      title: superWinsColumnNames[SuperWinsTableColumnNames.nominal],
      dataIndex: SuperWinsTableColumnNames.nominal,
      key: SuperWinsTableColumnNames.nominal,
      render: (nominal: number) => (
        <div className="SuperWinsColumns_CurrencyCell">
          {`${formatToCurrency({ amount: nominal, withoutFixed: true })} ${
            mainConstants.rubles
          }`}
        </div>
      )
    },
    {
      title: superWinsColumnNames[SuperWinsTableColumnNames.date],
      dataIndex: SuperWinsTableColumnNames.date,
      key: SuperWinsTableColumnNames.date,
      render: (date: Moment) => (
        <div className="SuperWinsColumns_DateCell">
          <div>{formatDateDefault(date)}</div>
        </div>
      )
    },
    {
      title: superWinsColumnNames[SuperWinsTableColumnNames.win_sum],
      dataIndex: SuperWinsTableColumnNames.win_sum,
      key: SuperWinsTableColumnNames.win_sum,
      render: (sum: number) => (
        <div className="SuperWinsColumns_CurrencyCell">
          {`${formatToCurrency({ amount: sum })} ${mainConstants.rubles}`}
        </div>
      )
    }
  ]
}

export const makeSuperWinTitle = (
  filterState: MainPageDataFilterType,
  dashboardSuperWins: DashboardItem
) => {
  if (filterState.data.halls.length > 1) {
    return mainConstants.dashboards.superWins.manyHallsTitle
  }

  if (dashboardSuperWins.data.details.length) {
    return mainConstants.dashboards.superWins.title
  }

  return mainConstants.dashboards.superWins.singleHallEmptyTitle
}

export const shouldSuperWinHideMoney = (
  filterState: MainPageDataFilterType,
  dashboardSuperWins: DashboardItem
) => {
  const hasMultipleHalls = filterState.data.halls.length > 1
  const hasNoDetails = dashboardSuperWins.data.details.length <= 0

  return hasMultipleHalls || (!hasMultipleHalls && hasNoDetails)
}

import React from "react"

import { formatToCurrency } from "constants/index"

import { LoyaltyGuestDashboardType } from "../types"

import balanceImg from "./bonus.png"
import persentImg from "./persent.png"
import accumulatedImg from "./accumulated.png"
import crownImg from "./crown.png"

const dashboardTextConfig = {
  bonus: {
    title: `Начисленные бонусы за период`,
    description: `Сумма бонусов, начисленных гостю на бонусный баланс, за выбранный период.`
  },
  status: {
    title: `Статус гостя`,
    description: `Статус гостя на текущий момент.`
  },
  cashback: {
    title: `Процент кэшбэка`,
    description: `Процент кэшбэка гостя на текущий момент, в соответствии со статусом.`
  },
  accumulatedBonus: {
    title: `Накопленные бонусы`,
    description: `Бонусы, которые были начислены гостю на карточку “КЭШБЭК” согласно условиям акции.`
  },
  nextLevel: {
    title: `Условия для повышения статуса`,
    description: `Информация об общем количестве баллов, которое необходимо набрать гостю для повышения статуса согласно условиям акции.`,
    max: `Максимальный кэшбэк`
  }
}

export default dashboardTextConfig

export const getConfig = (data: LoyaltyGuestDashboardType) => {
  return [
    {
      background: `#E7F5FF`,
      title: dashboardTextConfig.bonus.title,
      icon: (
        <img
          loading="lazy"
          className="DashboardItemIcon"
          alt={dashboardTextConfig.bonus.title}
          src={balanceImg}
        />
      ),
      value: formatToCurrency({ amount: data.bonus }),
      isCurrency: false,
      isString: true,
      tooltipText: dashboardTextConfig.bonus.description
    },
    {
      background: `#FFF9DB`,
      title: dashboardTextConfig.status.title,
      icon: (
        <img
          loading="lazy"
          className="DashboardItemIcon"
          alt={dashboardTextConfig.status.title}
          src={crownImg}
        />
      ),
      value: data.status,
      isCurrency: false,
      isString: true,
      tooltipText: dashboardTextConfig.status.description
    },
    {
      background: `#E6FCF5`,
      title: dashboardTextConfig.cashback.title,
      icon: (
        <img
          loading="lazy"
          className="DashboardItemIcon"
          alt={dashboardTextConfig.cashback.title}
          src={persentImg}
        />
      ),
      value: formatToCurrency({ amount: data.cashback }),
      isCurrency: false,
      isString: true,
      tooltipText: dashboardTextConfig.cashback.description
    },
    {
      background: `#F4FCE3`,
      title: dashboardTextConfig.accumulatedBonus.title,
      icon: (
        <img
          loading="lazy"
          className="DashboardItemIcon"
          alt={dashboardTextConfig.accumulatedBonus.title}
          src={accumulatedImg}
        />
      ),
      value: formatToCurrency({ amount: data.accumulatedBonus }),
      isCurrency: false,
      isString: true,
      tooltipText: dashboardTextConfig.accumulatedBonus.description
    }
  ]
}

import React from "react"
import {
  GuestBalanceType,
  GuestBalancePaginationType,
  GuestBalanceReportData
} from "./types"
import { SortOrders } from "Components/Table/types"
import { DashboardComponentConfig } from "Components/Dashboard/types"
import walletImg from "Components/icons/wallet-2.png"
import moneyImg from "Components/icons/dashboard_money.png"
import bonusImg from "Components/icons/dashboard_bonus.png"
import freeBetImg from "Components/icons/freebet.png"
import { formatToCurrency, wordsByNumber } from "../../constants"
import moment, { Moment } from "moment/moment"

export const guestBalanceConsts = {
  GUEST_BALANCE_FILTER: `GUEST_BALANCE_FILTER`,
  url: `v1/guests/balance`,
  urlFreeBets: `v1/guests/balance/freebets`,
  urlExcel: `v1/guests/balance/excel`,
  scroll: {
    x: 800,
    y: "calc(100vh - 17rem)"
  },
  zeroBalanceTitle: `Показывать кошельки \n с нулевыми балансами`,
  dashboards: {
    walletsTitle: `Количество \nкошельков`,
    bonusTitle: `Бонусный \nбаланс, Б`,
    moneyTitle: `Денежный \nбаланс, ₽`,
    freeBetTitle: `Общая сумма \nфрибет-балансов, Ф`,
    walletsTooltip: `Общее количество кошельков гостей в данном клубе с учетом фильтрации`,
    moneyTooltip: `Суммарный денежный баланс гостей в данном клубе с учетом фильтрации`,
    bonusTooltip: `Суммарный бонусный баланс гостей в данном клубе с учетом фильтрации`,
    freeBetTooltip: `Отображает общую сумму по фрибетным балансам гостей, зарегистрированным в клубе, согласно примененным фильтрам. Если фильтры не были применены - дашборд отображает общую сумму по всем фрибетным балансам гостей.`
  },
  mainTitle: `Балансы гостей`,
  walletsTitle: `Номер кошелька`,
  bonusTitle: `Бонусный баланс, Б`,
  moneyTitle: `Денежный баланс, ₽`,
  freeBetCountTitle: `Количество фрибетов`,
  freeBetSumTitle: `Общая сумма \nфрибет-балансов, Ф`,
  mainTooltip: `Раздел позволяет получить информацию на текущий момент по денежным, бонусным и фрибет-балансам гостей, зарегистрированным в клубе. Дашборды раздела отображают суммарную информацию по балансам, а также количество кошельков. Таблица отчета содержит информацию по каждому кошельку отдельно. По балансам доступна фильтрация по суммам и сортировка. При необходимости, данные возможно выгрузить в таблицу Excel. Информация представлена только в рамках одного клуба.`,
  defaultSort: {
    field: GuestBalanceType.walletId,
    order: SortOrders.desc
  },
  defaultBalance: {
    field: undefined
  },
  initialReport: {
    pending: false,
    total: 0,
    data: {
      totalMoney: null,
      totalBonus: null,
      totalWallets: null,
      totalFreeBetsSum: null,
      limits: {
        money: {
          min: 0,
          max: Number.MAX_SAFE_INTEGER
        },
        bonus: {
          min: 0,
          max: Number.MAX_SAFE_INTEGER
        },
        freeBetCount: {
          min: 0,
          max: Number.MAX_SAFE_INTEGER
        },
        freeBetSum: {
          min: 0,
          max: Number.MAX_SAFE_INTEGER
        }
      },
      wallets: []
    }
  },
  defaultSortConfig: {
    sort: {
      field: GuestBalanceType.money,
      order: SortOrders.desc
    }
  },
  pagination: {
    showText: "Показывать по",
    options: [
      {
        value: GuestBalancePaginationType.per_20,
        label: GuestBalancePaginationType.per_20
      },
      {
        value: GuestBalancePaginationType.per_40,
        label: GuestBalancePaginationType.per_40
      },
      {
        value: GuestBalancePaginationType.per_60,
        label: GuestBalancePaginationType.per_60
      }
    ]
  }
}

export const createDashboardConfig = (
  data: GuestBalanceReportData
): DashboardComponentConfig[] => [
  {
    background: `#FFF4E6`,
    title: guestBalanceConsts.dashboards.walletsTitle,
    icon: (
      <img
        loading="lazy"
        className="DashboardItemIcon"
        alt={guestBalanceConsts.dashboards.walletsTitle}
        src={walletImg}
      />
    ),
    customWidth: `136px`,
    value: data?.totalWallets || null,
    isCurrency: false,
    tooltipText: guestBalanceConsts.dashboards.walletsTooltip
  },
  {
    background: `#F3F0FF`,
    title: guestBalanceConsts.dashboards.moneyTitle,
    icon: (
      <img
        loading="lazy"
        className="DashboardItemIcon"
        alt={guestBalanceConsts.dashboards.moneyTitle}
        src={moneyImg}
      />
    ),
    value: data?.totalMoney || null,
    isCurrency: true,
    tooltipText: guestBalanceConsts.dashboards.moneyTooltip
  },
  {
    background: `#E7F5FF`,
    title: guestBalanceConsts.dashboards.bonusTitle,
    icon: (
      <img
        loading="lazy"
        className="DashboardItemIcon"
        alt={guestBalanceConsts.dashboards.bonusTitle}
        src={bonusImg}
      />
    ),
    value: data?.totalBonus || null,
    isCurrency: true,
    tooltipText: guestBalanceConsts.dashboards.bonusTooltip
  },
  {
    background: `#FFF9DB`,
    title: guestBalanceConsts.dashboards.freeBetTitle,
    icon: (
      <img
        loading="lazy"
        className="DashboardItemIcon"
        alt={guestBalanceConsts.dashboards.freeBetTitle}
        src={freeBetImg}
      />
    ),
    value: data?.totalFreeBetsSum || null,
    isCurrency: true,
    tooltipText: guestBalanceConsts.dashboards.freeBetTooltip
  }
]

export const createText = ({
  count,
  sum,
  walletId,
  hallId
}: {
  count: number
  sum: string
  walletId: number
  hallId: number
}) => ({
  title: `Фрибеты кошелька ${walletId} · Клуб № ${hallId}`,
  description: `Всего ${count} ${wordsByNumber(count, [
    "фрибет",
    "фрибета",
    "фрибетов"
  ])} на общую сумму ${formatToCurrency({
    amount: Number(sum),
    withoutFixed: true
  })} Ф`
})

const columnKeys = {
  value: {
    key: `value`,
    label: `Номинал фрибета, Ф`
  },
  endDatetime: {
    key: `endDatetime`,
    label: `Окончание действия`,
    format: `DD.MM.YYYY`
  },
  betType: {
    key: `betType`,
    label: `Тип фрибета`
  },
  betMinFactor: {
    key: `betMinFactor`,
    label: `Мин. коэф.`
  },
  betMaxFactor: {
    key: `betMaxFactor`,
    label: `Макс. коэф.`
  }
}

export const columns = [
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing">
        {columnKeys.endDatetime.label}
      </div>
    ),
    dataIndex: columnKeys.endDatetime.key,
    key: columnKeys.endDatetime.key,
    width: 200,
    render: (date: Moment) => (
      <div className="FlexRow">
        <span className="TextDefaultBold">
          {date ? moment(date).format(columnKeys.endDatetime.format) : `-`}
        </span>
      </div>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing">
        {columnKeys.betType.label}
      </div>
    ),
    dataIndex: columnKeys.betType.key,
    key: columnKeys.betType.key,
    render: (betType: string) => (
      <div className="FlexRow">
        <span className="TextDefault CapitalizeText">
          {betType ? betType : `-`}
        </span>
      </div>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing">{columnKeys.value.label}</div>
    ),
    dataIndex: columnKeys.value.key,
    key: columnKeys.value.key,
    width: 200,
    render: (value: number) => (
      <div className="FlexRow">
        <span className="TextDefault">
          {formatToCurrency({ amount: value, withoutFixed: true })}
        </span>
      </div>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing">
        {columnKeys.betMinFactor.label}
      </div>
    ),
    dataIndex: columnKeys.betMinFactor.key,
    key: columnKeys.betMinFactor.key,
    render: (betMinFactor: number) => (
      <div className="FlexRow">
        <span className="TextDefault">{betMinFactor}</span>
      </div>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing">
        {columnKeys.betMaxFactor.label}
      </div>
    ),
    dataIndex: columnKeys.betMaxFactor.key,
    key: columnKeys.betMaxFactor.key,
    render: (betMaxFactor: number) => (
      <div className="FlexRow">
        <span className="TextDefault">{betMaxFactor}</span>
      </div>
    )
  }
]

import React from "react"
import betRequest from "api/request"
import { IDevelopState } from "store/developer/types"
import { RouteComponentProps } from "react-router-dom"
import moment, { Moment } from "moment"

import { ChangePhoneDataType } from "./types"

const StatusComponent = ({ status }: { status: number }) => {
  let statusData

  switch (status) {
    case 1:
      statusData = {
        color: `#FCE000`,
        text: `Новый`,
        className: `ChangePhoneStatusNew`
      }
      break
    case 2:
      statusData = {
        color: `#EBFBEE`,
        text: `Одобрен`,
        className: `ChangePhoneStatusApproved`
      }
      break
    case 3:
      statusData = {
        color: `#FFF5F5`,
        text: `Отказано`,
        className: `ChangePhoneStatusReject`
      }
      break
    default:
      statusData = {
        color: `#FCE000`,
        text: `Новый`,
        className: `ChangePhoneStatusNew`
      }
  }

  return (
    <div
      className={`ChangePhoneStatusWrapper ${statusData.className}`}
      style={{ backgroundColor: statusData.color }}
    >
      {statusData.text}
    </div>
  )
}

export const changePhoneTableColumns = [
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">№</div>
    ),
    dataIndex: "id",
    key: "id",
    width: 50,
    render: (hall: number) => <div className="TableHeaderFirstTh">{hall}</div>
  },
  {
    title: () => <div className="TableHeaderWithoutSpacing">Старый номер</div>,
    dataIndex: "old_phone",
    key: "old_phone",
    width: 150
  },
  {
    title: () => <div className="TableHeaderWithoutSpacing">Новый номер</div>,
    dataIndex: "new_phone",
    width: 150,
    key: "new_phone"
  },
  {
    title: () => <div className="TableHeaderWithoutSpacing">Гость</div>,
    dataIndex: "fio",
    width: 220,
    key: "fio"
  },
  {
    title: () => <div className="TableHeaderWithoutSpacing">Дата создания</div>,
    dataIndex: "reg_dttm",
    width: 150,
    key: "reg_dttm",
    render: (reg_dttm: number) => (
      <div className="FlexRow">
        <div>{moment(reg_dttm).format(`DD.MM.YYYY`)}</div>
        <div className="NotifiChangePhoneTableColumnTime">
          {moment(reg_dttm).format(`HH:mm`)}
        </div>
      </div>
    )
  },
  {
    title: () => <div className="TableHeaderWithoutSpacing">Дата решения</div>,
    dataIndex: "close_dttm",
    key: "close_dttm",
    width: 150,
    render: (close_dttm: number, record: { status: number }) =>
      record.status && record.status !== 1 && !!close_dttm ? (
        <div className="FlexRow">
          <div>{moment(close_dttm).format(`DD.MM.YYYY`)}</div>
          <div className="NotifiChangePhoneTableColumnTime">
            {moment(close_dttm).format(`HH:mm`)}
          </div>
        </div>
      ) : (
        <div />
      )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing">Обработал заявку</div>
    ),
    dataIndex: "user_fullname",
    width: 150,
    key: "user_fullname"
  },
  {
    title: () => <div className="TableHeaderWithoutSpacing">Статус</div>,
    dataIndex: "status",
    width: 100,
    key: "status",
    render: (status: number) => <StatusComponent status={status} />
  }
]

type GetPhoneListProps = {
  logout: () => void
  developer: IDevelopState
  addDeveloperLink: (data: IDevelopState) => void
  history: RouteComponentProps["history"]
  listState: ChangePhoneDataType
  setData: (data: ChangePhoneDataType) => void
  pagination: { limit: number; offset: number }
  filter: {
    text: string | undefined
    dates: [Moment, Moment] | undefined
  }
}

const getPhoneList = ({
  logout,
  developer,
  addDeveloperLink,
  history,
  listState,
  setData,
  pagination,
  filter
}: GetPhoneListProps) => {
  setData({ ...listState, pending: true })
  const hallStorage = localStorage.getItem(`hall`)
  const hall = Number(JSON.parse(hallStorage || ``)[0])
  betRequest({
    logout,
    developer,
    addDeveloperLink,
    history,
    url: `order/phone`,
    method: `POST`,
    requestBody: {
      ...pagination,
      search: filter.text,
      dates: filter.dates,
      hall_id: hall
    }
  })
    .then(({ data, headers }) => {
      setData({ data, pending: false, total: headers[`total-count`] })
    })
    .catch(() => setData({ ...listState, pending: false }))
}

export default getPhoneList

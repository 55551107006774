import React from "react"

import {
  PromotionBonusesLegoConfig,
  PromotionBonusesTableItem
} from "../Lego/types"

import activatedImg from "Components/icons/dashboard/unique registration.png"

export const config: PromotionBonusesLegoConfig = {
  title: `Золотая лихорадка`,
  tool: `Отчет предназначен для просмотра информации о начислениях по акции Золотая лихорадка. Данные возможно посмотреть как общие по клубу, так и по конкретному гостю, за выбранный период. После применения фильтров появится информация о датах начислений, а также количество доступных к активации карт и номер кошелька гостя.`,
  filterStorageName: `PromotionBetBonus`,
  notActiveFilterDescription: `Отчёт о начислениях по акции “Золотая лихорадка”.`,
  tableUrl: `v1/promotions/gold-rush`,
  summaryUrl: `v1/promotions/gold-rush/summary`,
  summary: [
    {
      background: `#E6FCF5`,
      field: `cards_activated`,
      isCurrency: false,
      title: `Карт активировано`,
      tooltipText: `Количество карт, активированных гостями по акции, в рамках выбранного в фильтрах периода.`,
      icon: (
        <img
          loading="lazy"
          className="DashboardItemIcon"
          alt="Сумма полученных бонусов"
          src={activatedImg}
        />
      )
    }
  ],
  table: [
    {
      field: `approve_date`,
      title: `Дата подтверждения`,
      type: PromotionBonusesTableItem.date
    },
    {
      field: `approve_date`,
      title: `Время подтверждения`,
      type: PromotionBonusesTableItem.time
    },
    {
      field: `request_date`,
      title: `Время запроса`,
      type: PromotionBonusesTableItem.time
    },
    {
      field: `wallet_number`,
      title: `Номер кошелька`,
      type: PromotionBonusesTableItem.wallet
    },
    {
      field: `cards_count`,
      title: `Доступно для активации (шт.)`,
      type: PromotionBonusesTableItem.sum
    }
  ],
  filterTypeHall: `hall`,
  filterTypeGuest: `guest`,
  monthPeriod: "month"
}

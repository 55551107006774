export const ERROR_PAGE = "/ERROR_PAGE"
export const SERVER_ERROR_PAGE = "/SERVER_ERROR_PAGE"
export const ROOT = "/"

export const MAIN_PAGE = "/dashboard"
export const CASHBOX_PAGE = "/cashbox"
export const GAMES_PAGE = "/games"
export const CONTACTS_PAGE = "/contacts"
export const CONSOLIDATED_PAGE = "/summary"
export const NOTIFICATION_PAGE = "/tools/notifications"
export const MESSAGES_PAGE = "/messages"
export const CHANGE_PHONE_PAGE = "/order/changephone"
export const GUEST_INFO = "/guestinfo"
export const REPORT_CONSOLIDATED_CUPIS = "/reports/cupis"
export const REPORT_NONCASH_BALANCE_OPERATION =
  "/reports/noncash/balanceOperation"
export const REPORT_NONCASH_TRANSACTION = "/reports/noncash/transaction"
export const REPORT_NONCASH_CLUB_BALANCE = "/reports/noncash/hallbalance"
export const SUPPORT_PROPOSAL = "/support/proposal"
export const HANDBOOK_NEW_HALL = "/handbook/new-hall"
export const MAILING_PAGE = "/mailing"
export const SUPPORT_PENALTY_ADMIN = "/support/penalty/admin"
export const SUPPORT_PENALTY_USER = "/support/penalty/user"
export const VISIT = "/attendance"
export const GUIDEBOOK = "/guidebook"
export const SMS = "/sms"
export const GUESTS_HISTORY = "/guests_history"
export const SPORT_BOOM = "/sportboom"
export const GUESTS_BALANCE = "/guests_balance"
export const WIKI = "/wiki"
export const OFFICIAL_SMS = "/sms/service"
export const NOMINALS = "/nominal"
export const INSURANCES = "/insurance"
export const SUPERWIN = "/superwins"
export const TOP_THREE = "/top3"
export const RACE = "/race"
export const PAYROLLS = "/order/payrolls"
export const PAYMENT = "/order/payment"
export const ORDER_PAYOUTS = "/order/payouts"
export const REPORT_HALL = "/reports/hall"
export const HALL_CARD = "/hall/card"
export const REGISTRATION_CHECK = "/registration-check"
export const PROMOTION_BET_CHECK = "/promotions/bet_bonus"
export const PROMOTION_GOLD_RUSH = "/promotions/gold_rush"
export const PROMOTION_BIRTHDAY = "/promotions/guests_birthday"
export const SOPD_REPORT = "/sopd-report"
export const HALL_SPORT_BROADCAST = "/hall/sport_broadcast"
export const HALL_GEOSERVICES = "/hall/geoservices"
export const GUEST_FILTERS = "/guest/filters"

export const HISTORY_BETS = "/history/bets"

export const routeBackground = {
  default: `linear-gradient(180deg, #FFFFFF 0%, #F8F8F8 29.63%, #F4F4F4 100%)`,
  cashboxPage: `linear-gradient(180deg, #FFFFFF 0%, #F8F8F8 35%, #F4F4F4 100%)`,
  white: "#fff",
  root: "#f4f4f4"
}

export const hallsAddress = `hallsAddress`

export const baseLayoutText = {
  exit: "Выход",
  backToLk: "Перейти в личный кабинет",
  lkWiki: "ЛК. Справка"
}

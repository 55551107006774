import React from "react"
import { isEmpty } from "lodash"
import moment from "moment"
import { Moment } from "moment/moment"

import { getFilterStateFromLocalStorage } from "api/helpers"
import { TableFields } from "./types"

import { ReactComponent as EmptyTable } from "./components/empty_table.svg"

export const textConstants = {
  title: "Отчет для сверки СОПД",
  filterTitle: "Период отчёта",
  dashboard: { title: "Всего регистраций", color: "#F3F0FF" },
  dashboardTooltip:
    "Количество регистраций в клубе за выбранный в фильтрах период.",
  tooltip:
    "Отчет предназначен для получения списка гостей, которые были зарегистрированы в клубе, а также меняли персональные данные, для сверки наличия СОПД. Отчет возможно построить только по одному клубу и одному месяцу. Фильтр по периоду доступен только в рамках трех последних месяцев.",
  notActiveFilter: {
    title:
      "Чтобы получить отчёт, выберите нужные фильтры \nи нажмите кнопку «Применить»",
    description:
      "Отчет для возможности сверки наличия СОПД, которое в обязательном порядке гость подписывает при регистрации.",
    icon: <EmptyTable />
  }
}

export const filterStorageName = "REGISTRATION_REPORT_FILTER"

export const initialFilterValues = {
  date: null
}

export const getInitialFilter = (hall: string) => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {
      date: null,
      hall
    },
    filterStorageName
  )

  const haveFilterFromStorage = !isEmpty(initFilterStateFromStorage)

  return {
    isOpen: true,
    mobileFilter: false,
    isActive: haveFilterFromStorage,
    data: initFilterStateFromStorage
  }
}
export const getCurrenHallFromFilter = (filter: {
  hall: string
  halls?: number[]
}) => (filter?.halls || [])[0] || Number(filter?.hall)

export const columnsTextTitle: Record<keyof typeof TableFields, string> = {
  guest_name: "ФИО гостя",
  register_date: "Дата регистрации",
  sequence_number: "№ п/п",
  change_date: "Дата изменений ПД"
}

export const getColumns = () => {
  return [
    {
      title: () => (
        <div className="TableHeaderWithoutSpacing PaddingLeft24">
          {columnsTextTitle.sequence_number}
        </div>
      ),
      dataIndex: TableFields.sequence_number,
      key: TableFields.sequence_number,
      render: (value: number) => <div className="PaddingLeft24">{value}</div>
    },
    {
      title: () => <div>{columnsTextTitle.guest_name}</div>,
      dataIndex: TableFields.guest_name,
      key: TableFields.guest_name,
      render: (value: number) => (
        <div className="RegistrationTableGuest">{value}</div>
      )
    },
    {
      title: () => <div>{columnsTextTitle.register_date}</div>,
      dataIndex: TableFields.register_date,
      key: TableFields.register_date,
      render: (date: Moment) => <div>{moment(date).format("DD.MM.YYYY")}</div>
    },
    {
      title: () => <div>{columnsTextTitle.change_date}</div>,
      dataIndex: TableFields.change_date,
      key: TableFields.change_date,
      render: (date: Moment) => <div>{moment(date).format("DD.MM.YYYY")}</div>
    }
  ]
}

export const emptyTableConfig = {
  desktop: {
    title: `Ничего не найдено`,
    subtitle: `По заданным фильтрам ничего не найдено`
  },
  mobile: {
    title: `Ничего не найдено`,
    subtitle: `Гость с таким \nномером телефона не \nзарегистрирован`
  }
}

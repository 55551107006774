import React, { useState } from "react"

import { CheckBoxComponent } from "Components/CheckBox"
import InputComponent from "Components/Input"

import { FilterProps } from "./index"
import { customFilterText, rangeText } from "./constants"

import { FilterRangeFields } from "../types"

interface RangeComponentProps extends FilterProps {
  label: string
  field: FilterRangeFields
}

const RangeComponent = ({
  filter,
  setFilter,
  field,
  label
}: RangeComponentProps) => {
  const [from, setFrom] = useState<number | undefined>(undefined)
  const [to, setTo] = useState<number | undefined>(undefined)

  const errorFromTo = from && to && from >= to
  return (
    <div className="FlexColumn Gap8">
      <CheckBoxComponent
        checked={filter[field]?.enabled}
        onChange={({ target: { checked } }) =>
          setFilter({
            ...filter,
            [field]: { range: undefined, enabled: checked }
          })
        }
        label={label}
      />
      <div className="FlexColumn">
        <div className="FlexRow Gap8">
          <InputComponent
            disabled={!filter[field]?.enabled}
            value={from}
            onChange={({ target: { value } }) => {
              const isNumber = !!Number(value) ? Number(value) : 0
              const newValue = value === `` ? undefined : isNumber
              setFrom(newValue)
              setFilter({
                ...filter,
                [field]: {
                  ...filter[field],
                  range: { ...filter[field]?.range, from: newValue }
                }
              })
            }}
            isMiddle={true}
            prefix={
              <div className="TextDefault Gray500Color">{rangeText.from}</div>
            }
          />
          <InputComponent
            disabled={!filter[field]?.enabled}
            value={to}
            onChange={({ target: { value } }) => {
              const isNumber = !!Number(value) ? Number(value) : 0
              const newValue = value === `` ? undefined : isNumber
              setTo(newValue)
              setFilter({
                ...filter,
                [field]: {
                  ...filter[field],
                  range: { ...filter[field]?.range, to: newValue }
                }
              })
            }}
            isMiddle={true}
            prefix={
              <div className="TextDefault Gray500Color">{rangeText.to}</div>
            }
          />
        </div>
        {!!errorFromTo && (
          <span className="ErrorText">{customFilterText.errorRange}</span>
        )}
      </div>
    </div>
  )
}
export default RangeComponent

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  games,
  initialNominalsErrors,
  initialNominalsNew,
  initialNominalsNewMinMax,
  nominalsText,
  sections
} from "../constants"
import {
  customizer,
  generateTableData,
  isValidPhoneNumber,
  makeBetColumnsAndData,
  useNominalsRequests
} from "../helper"
import {
  ChangeTabColumn,
  MaxLimit,
  NominalSectionKeys,
  NominalsErrors,
  NominalsGame,
  NominalsNew,
  TableRow,
  TableRowId,
  TemplateDataState
} from "../types"
import { ReactComponent as Info20 } from "Components/icons/nominals/info_20.svg"
import { GameTabItem } from "./GameTabItem"
import TableComponent from "Components/TableNew"
import { ColumnProps } from "antd/lib/table"
import Input from "Components/Input"
import Title from "Components/Title"
import { TemplateCard } from "./TemplateCard"
import { ButtonPrimary } from "Components/Button"
import produce from "immer"
import _ from "lodash"
import { RequestModal } from "./RequestModal"
import { isMobile, moneyRender } from "constants/index"
import { MobileTableCard } from "./MobileTableCard"
import { Carousel } from "antd"

export const ChangeTab = () => {
  const [nominalsData, setNominalsData] = useState<NominalsGame[] | null>(null)
  const [activeGameId, setActiveGameId] = useState(games[0].id)
  const [activeGame, setActiveGame] = useState<NominalsGame | null>(null)
  const [nominalsNew, setNominalsNew] = useState<NominalsNew>(
    initialNominalsNew
  )
  const [nominalsNewForRequest, setNominalsNewForRequest] = useState<
    NominalsNew
  >(initialNominalsNew)
  const [isModalActive, setIsModalActive] = useState(false)
  const [nominalsErrors, setNominasErrors] = useState<NominalsErrors>(
    initialNominalsErrors
  )
  const [templateData, setTemplateData] = useState<TemplateDataState>({
    isActive: false,
    id: null
  })
  const [loading, setLoading] = useState({
    validate: false,
    change: false,
    manage: false
  })
  const [isChangedSuccess, setIsChangedSuccess] = useState(false)
  const [modalInputData, setModalInputData] = useState({
    phone: "",
    name: ""
  })
  const [isDisabledVerify, setIsDisabledVerify] = useState(false)
  const mainWrapperRef = useRef<HTMLDivElement>(null)

  const disabledSendButton = useMemo(
    () =>
      !(
        isValidPhoneNumber(modalInputData.phone) &&
        modalInputData.name.length > 0
      ),
    [modalInputData]
  )

  const {
    getCurrentNominals,
    validateNewNominals,
    changeNewNominals
  } = useNominalsRequests({
    validateArgs: { game_id: activeGameId as number, ...nominalsNewForRequest },
    changeArgs: {
      game_id: activeGameId as number,
      ...nominalsNewForRequest,
      phone: modalInputData.phone,
      name: modalInputData.name
    }
  })

  useEffect(() => {
    setLoading(prev => ({ ...prev, manage: true }))
    getCurrentNominals()
      .then(res => {
        setNominalsData(res.data)
      })
      .finally(() => {
        setLoading(prev => ({ ...prev, manage: false }))
      })
  }, [])

  useEffect(() => {
    setNominalsNew(!isMinMax ? initialNominalsNew : initialNominalsNewMinMax)
    setNominasErrors(initialNominalsErrors)
  }, [activeGame])

  const handleCloseModal = useCallback(() => {
    setIsModalActive(false)
  }, [])

  const handleTemplateActivated = useCallback((data: TemplateDataState) => {
    setTemplateData(data)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
    const { id, isActive, ...rest } = templateData
    if (isActive) {
      setNominalsNew(prevState => {
        let next = {
          ...prevState
        }
        if (!rest[NominalSectionKeys.main]) {
          next = {
            ...next,
            [NominalSectionKeys.main]: {
              ...initialNominalsNew[NominalSectionKeys.main]
            }
          }
        }
        if (!rest[NominalSectionKeys.vip]) {
          next = {
            ...next,
            [NominalSectionKeys.vip]: {
              ...initialNominalsNew[NominalSectionKeys.vip]
            }
          }
        }
        return { ...next, ...rest }
      })
    } else {
      setNominalsNew({
        ...initialNominalsNew,
        mergeNeeded: true //TODO fix it to clear template
      })
    }
  }, [templateData])

  useEffect(() => {
    if (activeGame && nominalsNew.mergeNeeded) {
      const nextActiveGame = {
        [NominalSectionKeys.vip]: activeGame[NominalSectionKeys.vip],
        [NominalSectionKeys.main]: activeGame[NominalSectionKeys.main]
      }
      const obj1 = _.cloneDeep(nextActiveGame)
      sections.forEach(prop => {
        if (obj1[prop]) {
          if (Array.isArray(obj1[prop])) {
            ;((obj1[prop] as unknown) as any[]).forEach((item: any) => {
              if (_.isObject(item.max_limit)) {
                item.limits = item.max_limit
              }
            })
          } else {
            if (_.isObject(obj1[prop].max_limit)) {
              obj1[prop].limits = obj1[prop].max_limit as MaxLimit
            }
          }
        }
      })
      const result = _.mergeWith({}, obj1, nominalsNew, customizer)
      setIsDisabledVerify(
        _.isEqual(
          nominalsNew,
          isMinMax ? initialNominalsNewMinMax : initialNominalsNew
        )
      )
      setNominalsNewForRequest(result)
    } else {
      setNominalsNewForRequest(nominalsNew)
    }
  }, [nominalsNew, activeGame])

  useEffect(() => {
    setActiveGame(
      nominalsData?.find(game => game.game_id === activeGameId) || null
    )
  }, [activeGameId, nominalsData])

  const handleActiveGameIdClick = useCallback((id: number) => {
    setActiveGameId(id)
  }, [])

  const { isMinMax, vipTableData, mainTableData } = generateTableData(
    activeGame ?? undefined
  )

  let columns: ColumnProps<ChangeTabColumn>[] = [
    {
      title: () => (
        <div className="NominalsBetTitle">{nominalsText.zoneType}</div>
      ),
      dataIndex: "id",
      key: "id",
      width: 400,
      render: (value: TableRowId) => (
        <div className="ChangeTabColWrapper">
          <div className="ChangeTabColWrapper_TitleImageContainer">
            {value.img}
            <div className="ChangeTabColWrapper_TitleImageContainer_Text">
              {value.title}
            </div>
          </div>
          <div className="ChangeTabColWrapper_TitleImageContainer_Text-secondary">
            {nominalsText.change}
          </div>
        </div>
      )
    }
  ]

  if (activeGame) {
    columns = [
      ...columns,
      ...activeGame?.main?.nominals.map(nominal => ({
        title: `${nominalsText.nominalColName} ${nominal.id}`,
        dataIndex: `nominal_${nominal.id}`,
        key: `nominal_${nominal.id}`,
        render: (value: TableRow) => (
          <div className="ChangeTabColNominalChange_Wrapper">
            <div className="ChangeTabColNominalChange_Title">{`${moneyRender(
              (value?.value as any) as number
            )} ${nominalsText.rubles}`}</div>
            <Input
              type="number"
              hideControls
              disableScrollEvent
              value={
                nominalsNew[value.zone as NominalSectionKeys]?.nominals?.find(
                  el => el.id === nominal.id
                )?.value
              }
              errors={nominalsErrors[value.zone as NominalSectionKeys]?.nominals
                ?.filter(el => el.id === nominal.id)
                .map(el => el.error)}
              greyBackground
              className="ChangeTabColNominalChange_Input"
              onChange={event => {
                const foundedIdx = nominalsNew[
                  value.zone as NominalSectionKeys
                ].nominals.findIndex(el => el.id === nominal.id)
                if (foundedIdx > -1) {
                  const nextNominals = produce(nominalsNew, draft => {
                    draft[value.zone as NominalSectionKeys].nominals[
                      foundedIdx
                    ].value = Number(event.target.value)
                  })
                  setNominalsNew(nextNominals)
                } else {
                  const nextNominals = produce(nominalsNew, draft => {
                    draft[value.zone as NominalSectionKeys].nominals.push({
                      id: nominal.id,
                      value: Number(event.target.value)
                    })
                  })
                  setNominalsNew(nextNominals)
                }
              }}
            />
          </div>
        )
      }))
    ]
  }

  if (!isMinMax) {
    columns = [
      ...columns,
      {
        title: nominalsText.maxLimit,
        dataIndex: "max_limit",
        key: "max_limit",
        render: (value: TableRow) => (
          <div className="ChangeTabColNominalChange_Wrapper">
            <div className="ChangeTabColNominalChange_Title_MaxLimit">{`${moneyRender(
              (value.value as any) as number
            )} ${nominalsText.rubles}`}</div>
            <Input
              type="number"
              hideControls
              disableScrollEvent
              value={String(
                nominalsNew[value.zone as NominalSectionKeys].max_limit
              )}
              error={
                nominalsErrors?.[value.zone as NominalSectionKeys]?.max_limit
              }
              greyBackground
              className="ChangeTabColNominalChange_Input"
              onChange={({ target }) => {
                const nextNominals = produce(nominalsNew, draft => {
                  draft[value.zone as NominalSectionKeys].max_limit = Number(
                    target.value
                  )
                })
                setNominalsNew(nextNominals)
              }}
            />
          </div>
        )
      }
    ]
  }

  const {
    betColumnsMain,
    betDataMain,
    betDataVip,
    betColumnsVip
  } = makeBetColumnsAndData({
    activeGame,
    nominalsNew,
    nominalsErrors,
    setNominalsNew,
    isMinMax
  })

  const hideVipTemplate = isMinMax
    ? betDataVip.length <= 0 && betColumnsVip.length <= 0
    : vipTableData.length <= 0

  const validateNominalsCb = useCallback(() => {
    setLoading(prev => ({ ...prev, validate: true }))
    validateNewNominals()
      .then(() => {
        setNominasErrors(initialNominalsErrors)
        setIsModalActive(true)
      })
      .catch(resp => {
        setNominasErrors(resp.data)
        mainWrapperRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start"
        })
      })
      .finally(() => {
        setLoading(prev => ({ ...prev, validate: false }))
      })
  }, [validateNewNominals])

  const changeNominalsCb = useCallback(() => {
    setLoading(prev => ({ ...prev, change: true }))
    changeNewNominals()
      .then(() => {
        setIsChangedSuccess(true)
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
      .finally(() => {
        setLoading(prev => ({ ...prev, change: false }))
      })
  }, [changeNewNominals])

  const handleModalPhoneChange = useCallback(
    e => {
      setModalInputData(prev => ({ ...prev, phone: e.maskedValue }))
    },
    [setModalInputData]
  )

  const handleModalNameChange = useCallback(
    e => {
      setModalInputData(prev => ({ ...prev, name: e.target.value }))
    },
    [setModalInputData]
  )

  const handleCloseSuccessModal = useCallback(() => {
    setNominalsNew(!isMinMax ? initialNominalsNew : initialNominalsNewMinMax)
    setIsModalActive(false)
    setIsChangedSuccess(false)
  }, [isMinMax])

  return (
    <div className="ChangeTabWrapper" ref={mainWrapperRef}>
      <div className="ChangeTabTennisContainer">
        {games.map(game => (
          <GameTabItem
            {...game}
            key={game.id}
            onActiveGameIdClick={handleActiveGameIdClick}
            isActive={game.id === activeGameId}
          />
        ))}
      </div>
      {activeGame?.note && (
        <div className="ChangeTabTennisContainer_tooltip">
          <Info20 />
          <div
            className="ChangeTabTennisContainer_tooltip_text"
            dangerouslySetInnerHTML={{ __html: activeGame.note }}
          />
        </div>
      )}
      {isMinMax ? (
        isMobile ? (
          <div className="MobileTableCardsContainer">
            <MobileTableCard
              data={mainTableData}
              columns={columns}
              minMaxData={betDataMain}
              minMaxColumns={betColumnsMain}
              isMinMax
            />
            {!!betDataVip.length && !!betColumnsVip.length && (
              <MobileTableCard
                data={vipTableData}
                columns={columns}
                minMaxData={betDataVip}
                minMaxColumns={betColumnsVip}
                isMinMax
              />
            )}
          </div>
        ) : (
          <div className="NominalsTablesWrapper">
            <TableComponent
              loading={loading.manage}
              columns={columns || []}
              data={mainTableData}
              expandable={{
                columnWidth: 0,
                expandedRowRender: () => (
                  <TableComponent
                    data={betDataMain}
                    columns={betColumnsMain}
                    tableClassName="NominalsBetTable"
                    wrapperClassName="NominalsBetTableWrapper"
                  />
                ),
                defaultExpandAllRows: true,
                expandIcon: () => null,
                fixed: "left"
              }}
            />
            {!!betDataVip.length && !!betColumnsVip.length && (
              <TableComponent
                loading={loading.manage}
                columns={columns || []}
                data={vipTableData}
                expandable={{
                  columnWidth: 0,
                  expandedRowRender: () => (
                    <TableComponent
                      data={betDataVip}
                      columns={betColumnsVip}
                      tableClassName="NominalsBetTable"
                      wrapperClassName="NominalsBetTableWrapper"
                    />
                  ),
                  defaultExpandAllRows: true,
                  expandIcon: () => null,
                  fixed: "left"
                }}
              />
            )}
          </div>
        )
      ) : isMobile ? (
        <div className="MobileTableCardsContainer">
          <MobileTableCard data={mainTableData} columns={columns} />
          <MobileTableCard data={vipTableData} columns={columns} />
        </div>
      ) : (
        <TableComponent
          loading={loading.manage}
          columns={columns || []}
          data={[...mainTableData, ...vipTableData]}
          width={900}
        />
      )}

      <Title
        titleText={nominalsText.mockTitle}
        tooltipText={nominalsText.mockTooltip}
        wrapperClass="ChangeTab_Nominals-mock-title"
      />

      <div className="NominalsTemplateCardsContainer" key={activeGameId}>
        {isMobile ? (
          <Carousel className="NominalsTemplatesCarousel" dotPosition="bottom">
            {activeGame?.templates.map((template, idx) => (
              <TemplateCard
                key={template.nominals.length * idx}
                template={template}
                idx={idx + 1}
                isMinMax={isMinMax}
                onTemplateActivated={handleTemplateActivated}
                isActive={idx + 1 === templateData.id}
                isVipCheckBoxDisabled={hideVipTemplate}
              />
            ))}
          </Carousel>
        ) : (
          activeGame?.templates.map((template, idx) => (
            <TemplateCard
              key={template.nominals.length * idx}
              template={template}
              idx={idx + 1}
              isMinMax={isMinMax}
              onTemplateActivated={handleTemplateActivated}
              isActive={idx + 1 === templateData.id}
              isVipCheckBoxDisabled={hideVipTemplate}
            />
          ))
        )}
      </div>

      <ButtonPrimary
        className="NominalsSendButton"
        onClick={validateNominalsCb}
        loading={loading.validate}
        disabled={isDisabledVerify}
      >
        {nominalsText.sendButton}
      </ButtonPrimary>
      {isModalActive && (
        <RequestModal
          loading={loading.change}
          showSuccessImg={isChangedSuccess}
          isMinMax={isMinMax}
          nominalsData={nominalsNewForRequest}
          onClose={handleCloseModal}
          onCloseSuccess={handleCloseSuccessModal}
          onChangeSend={changeNominalsCb}
          onNameInputChange={handleModalNameChange}
          onPhoneInputChange={handleModalPhoneChange}
          disabled={disabledSendButton}
          hideVipTemplate={hideVipTemplate}
          userName={modalInputData.name}
        />
      )}
    </div>
  )
}

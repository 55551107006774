import React from "react"
import { CreateStepProps } from "../../types"
import { smsConstants } from "../../constants"
import { RadioGroupComponent } from "Components/Radio"
import { canShowStep2, getTextItemsByTemplateId } from "../../helper"
import { InputTextAreaComponent } from "Components/Input"

const Step2 = ({
  smsState,
  setSmsState,
  templateSmsTextSelected
}: CreateStepProps) => {
  const showStep2 = canShowStep2(smsState)
  return (
    <div className="CreateSms_step">
      <div
        className={
          showStep2 ? "CreateSms_stepTitle" : "CreateSms_stepTitle_inactive"
        }
      >
        <span>{smsConstants.step2.orderNumber}</span>
        <span>
          {templateSmsTextSelected
            ? smsConstants.step2.usualTitle
            : smsConstants.step2.customTitle}
        </span>
      </div>

      {showStep2 ? (
        <section className="CreateSms_inputWrapper CreateSms_inputWrapper_wide">
          {templateSmsTextSelected && (
            <span className="CreateSms_inputInfo">
              {smsConstants.step2.info}
            </span>
          )}
          {templateSmsTextSelected ? (
            <RadioGroupComponent
              error={smsState.errors?.sms?.text}
              value={smsState.selectedTextId}
              onChange={({ target: { value } }) => {
                setSmsState({
                  ...smsState,
                  ...smsConstants.paramsToResetSmsState,
                  extraParams: {},
                  selectedTextId: value
                })
              }}
              options={getTextItemsByTemplateId(smsState)}
            />
          ) : (
            <InputTextAreaComponent
              className="CreateSms_customText"
              greyBackground
              error={smsState.errors?.sms?.text}
              value={smsState.customSmsText}
              placeholder={smsConstants.step2.customPlaceholder}
              onChange={({ target: { value } }) => {
                setSmsState({
                  ...smsState,
                  customSmsText: value
                })
              }}
            />
          )}
        </section>
      ) : (
        <hr className="CreateSms_divider" />
      )}
    </div>
  )
}

export default Step2

const cashboxConstants = {
  title: `Касса`,
  tooltip:
    "Отчёт о движении денежных средств на кассе, таких как выручка, выплаты, остаток как для наличных так и безналичных средств, а также инкассации.",
  switcher: `Вид данных:`,
  notActive: {
    title: `Чтобы получить отчёт по балансу кассы вам необходимо выбрать нужные
		параметры фильтрации`,
    description: `Отчёт о движении денежных средств на кассе, таких как выручка, выплаты,
		остаток как для наличных так и безналичных средств, а также инкассации`
  },
  dashboards: {
    qiwi_balance: {
      backgroundColor: `#FFF4E8`,
      title: `Баланс для выплат, ₽`,
      tooltip: `Расчетный баланс клуба/клубов для безналичных выплат на банковские карты гостей на текущий момент.`
    },
    money_balance: {
      backgroundColor: `#F3F0FF`,
      title: `Денежный баланс, ₽`,
      tooltip: `Суммарный денежный баланс гостей в данном клубе/клубах.`
    },
    bonus_balance: {
      backgroundColor: `#E7F5FF`,
      title: `Бонусный баланс, Б`,
      tooltip: `Суммарный бонусный баланс гостей в данном клубе/клубах.`
    },
    cashbox_result: {
      backgroundColor: `#E6FCF5`,
      title: `Кассовый результат`,
      tooltip: `Разница между пополнениями и выплатами по кассе в данном клубе/клубах за выбранный период.`
    }
  },
  qiwi: {
    title: `Баланс для выплат, ₽`,
    color: `#FFF4E8`,
    name: `value`
  },
  total: "Итого"
}

export default cashboxConstants

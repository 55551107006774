import axios, { Method, AxiosPromise, CancelTokenSource } from "axios"

import { RouteComponentProps } from "react-router-dom"

import { IDevelopState } from "store/developer/types"
import { ERROR_PAGE } from "Containers/BaseLayout/constants"
import { makeLogout } from "Containers/Authentication/helper"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import produce from "immer"
import * as Sentry from "@sentry/browser"

interface BetRequest {
  url: string
  method?: Method
  logout: () => void
  requestBody?: any
  params?: unknown
  history: RouteComponentProps["history"]
  withHall?: (string | CheckboxValueType)[]
  developer?: IDevelopState
  addDeveloperLink?: (data: IDevelopState) => void
  contentTpe?: string
  customBodyData?: FormData
  customRootUrl?: string
  cancelToken?: CancelTokenSource
  skip404Handler?: boolean
}

/*
  betRequest - unified method for any requests
  params:
    - url: request url directory (domain add from process.env)
    - method: GET/POST/PUT..etc
    - logout: action for logout user
    - withHall: add hall from localStorage to request body
    - requestBody: request body object
    - params: for pagination, sort and etc axios params
    - developer/addDeveloperLink: developer main reducer/action - information only for users with role developer
    - history: for redirect after request to errors pages by code's
*/

const betRequest = ({
  url,
  method,
  logout,
  withHall,
  requestBody,
  params,
  developer,
  addDeveloperLink,
  history,
  contentTpe,
  customRootUrl,
  cancelToken,
  skip404Handler
}: BetRequest): AxiosPromise => {
  const userFromStorage: string | undefined =
    localStorage.getItem(`user`) || undefined
  const user: { token: string; hall: number } | undefined = userFromStorage
    ? JSON.parse(userFromStorage)
    : userFromStorage
  let requestBodyNext = requestBody
  if (requestBody?.filter?.halls) {
    requestBodyNext = produce(requestBodyNext, (draft: any) => {
      draft.filter.halls = requestBodyNext.filter.halls.map((x: any) =>
        Number(x)
      )
    })
  }
  return axios(
    `${customRootUrl ? customRootUrl : process.env.REACT_APP_AUTH_URL}/${url}`,
    {
      method,
      withCredentials: true,
      data: withHall
        ? { ...requestBodyNext, halls: withHall.map(x => Number(x)) || [] }
        : requestBodyNext,
      params,
      cancelToken: cancelToken?.token,
      headers: {
        "Content-Type": contentTpe ? contentTpe : `application/json`,
        token: user ? user.token : ``
      }
    }
  )
    .then(response => {
      localStorage.removeItem(`error`)
      const { headers } = response
      if (headers[`x-debug-token-link`] && addDeveloperLink) {
        addDeveloperLink({
          ...developer,
          [url]: { url, link: headers[`x-debug-token-link`] }
        })
      }
      return response
    })
    .catch(error => {
      //sentry
      Sentry.captureException(error, { user: { hall: user?.hall } })
      // redirect on error page's by code or logout for 401/403 statuses
      if (!history) makeLogout({ logout })
      if (history && error.response) {
        if (!skip404Handler && error.response.status === 404) {
          localStorage.setItem(`error`, `404`)
          history.push(ERROR_PAGE)
        } else if (
          error.response.status === 403 ||
          error.response.status === 401
        ) {
          makeLogout({ logout })
        } else if (
          error.response.status === 500 ||
          error.response.status === 502
        ) {
          localStorage.setItem(`error`, `500`)
          history.push(ERROR_PAGE)
        } else if (error.response.status === 504) {
          localStorage.setItem(`error`, `504`)
          history.push(ERROR_PAGE)
        } else if (!skip404Handler && error.response.status !== 400) {
          localStorage.setItem(`error`, error.response.status)
          history.push(ERROR_PAGE)
        }
      }
      if (skip404Handler) {
        throw error.response
      } else {
        throw error
      }
    })
}

export const getRequestTotal = (headers: any) =>
  Number(headers[`total-count`]) || 0

export const betRequestWithCancelToken = (
  params: BetRequest
): { request: () => AxiosPromise; cancelToken: CancelTokenSource } => {
  const cancelToken = axios.CancelToken.source()
  const request = () => betRequest({ ...params, cancelToken })
  return { request, cancelToken }
}

export default betRequest

import React, { useState, useEffect } from "react"

import Filter from "./Filter"
import EditModal from "./EditModal"
import SpinComponent from "Components/Spin"
import {
  ChangePhoneFilterType,
  ChangePhoneTableData,
  ChangePhoneDataType
} from "./types"
import getPhoneList, { changePhoneTableColumns } from "./helper"

import { ReactComponent as FilterMobileIcon } from "Components/icons/filter_settings.svg"
import { ReactComponent as FilterIcon } from "Components/icons/filter_arrow.svg"
import { ReactComponent as HelpIcon } from "Components/icons/help_24.svg"

import EmptyTable from "Components/EmptyTable"
import TooltipComponent from "Components/Tooltip"
import BaseFooter from "Components/Footer"
import NewTableComponent from "Components/TableNew"

import { useNow, useRequestBody } from "api/hooks"

import { useHistory } from "react-router-dom"
import { logoutUser } from "store/auth/actions"
import { useDispatch, useSelector } from "react-redux"
import { IDevelopState } from "store/developer/types"
import { getDeveloperList } from "store/developer/selector"
import { addLink } from "store/developer/actions"

const isMobile = window.innerWidth < 921

const ChangePhoneComponent = () => {
  const history = useHistory()
  const logout: () => void = () => dispatch(logoutUser())
  const dispatch = useDispatch()
  const developer: IDevelopState = useSelector(getDeveloperList)
  const addDeveloperLink: (data: IDevelopState) => void = data =>
    dispatch(addLink(data))
  const { timestamp, refresh } = useNow()

  const [filterState, setFilterState] = useState<ChangePhoneFilterType>({
    data: {
      dates: undefined,
      text: undefined
    },
    isOpen: false
  })
  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(false)

  const [listState, setListState] = useState<ChangePhoneDataType>({
    pending: false,
    data: [],
    total: 0
  })

  const { pagination, setPagination } = useRequestBody()

  const requestDeps = JSON.stringify({ ...filterState.data, ...pagination })

  useEffect(() => {
    getPhoneList({
      developer,
      addDeveloperLink,
      history,
      logout,
      pagination: { limit: pagination.limit, offset: pagination.offset },
      listState,
      setData: setListState,
      filter: filterState.data
    })
  }, [timestamp, requestDeps])
  // edit modal
  const [modalData, setModalData] = useState<ChangePhoneTableData | null>(null)

  return (
    <div className="FlexRow">
      <div>
        <div
          className={`MainPageContentWrapper ${
            filterState.isOpen
              ? `MainPageContentWrapperFilter`
              : `MainPageContentWrapperUnFilter`
          }`}
        >
          <div
            className="MobileFilterIcon"
            onClick={() => setMobileFilterOpen(true)}
          >
            <FilterMobileIcon />
          </div>
          <div className="MainPageContentRestrictions">
            <div className="MainPageTitle FlexRow">
              <div>Смена телефона</div>
              <TooltipComponent
                title="Раздел отображает вновь поступившие и уже обработанные заявки на смену номера телефона в аккаунтах гостей, созданные в кассовом модуле. В данном разделе можно как одобрить так и отклонить заявку. Номер телефона гостя в аккаунте изменится сразу же после одобрения заявки в личном кабинете. Отчёт по заявкам формируется за необходимый период.
Важно помнить, что при одобрении заявки на смену номера телефона, произойдут изменения в аккаунте гостя, который един для всей сети клубов BetBoom."
                direction={isMobile ? `bottom` : `rightTop`}
              >
                <div className="MainPageTitleHelp">
                  <HelpIcon />
                </div>
              </TooltipComponent>
            </div>
            {!!listState.data && !!listState.data.length && (
              <div className="ChangePhoneTableWrapper">
                <NewTableComponent
                  pagination={{
                    value: pagination,
                    total: listState.total,
                    onChange: setPagination
                  }}
                  loading={listState.pending}
                  width={1200}
                  withTotal={false}
                  columns={changePhoneTableColumns}
                  data={listState.data}
                  onRow={(record: ChangePhoneTableData) => {
                    return {
                      onClick: () => {
                        if (record.status === 1) {
                          setModalData(record)
                        }
                      }
                    }
                  }}
                  rowClassNameByRecord={(record: any) =>
                    !!record.status && record.status === 1
                      ? `TableComponentChangePhoneActiveRow ExpandableTableRow`
                      : `TableComponentChangePhoneDisabledRow`
                  }
                />
              </div>
            )}
            {listState.pending && <SpinComponent color="#F8E800" />}
            {!!listState.data &&
              !!listState.data.length &&
              !listState.pending && <div />}
            {!!listState.data &&
              !listState.data.length &&
              !listState.pending &&
              (!!filterState.data.text || !!filterState.data.dates) && (
                <EmptyTable />
              )}
            <div className="FlexRow">
              <div
                className={`MainPageFilterButtonWrapper ${
                  filterState.isOpen
                    ? `MainPageFilterButtonWrapperActive`
                    : `MainPageFilterButtonWrapperUnActive`
                }`}
                onClick={() =>
                  setFilterState({
                    ...filterState,
                    isOpen: !filterState.isOpen
                  })
                }
              >
                <FilterIcon />
              </div>
            </div>
          </div>
          {mobileFilterOpen && (
            <Filter
              filterState={filterState}
              setFilterState={setFilterState}
              mobileFilterOpen={mobileFilterOpen}
              setMobileFilterOpen={setMobileFilterOpen}
              clearPagination={() =>
                setPagination({ ...pagination, limit: 20, offset: 0 })
              }
            />
          )}
        </div>
        <BaseFooter />
      </div>
      {!!modalData && (
        <EditModal
          data={modalData}
          close={() => setModalData(null)}
          refresh={refresh}
        />
      )}
      <Filter
        filterState={filterState}
        setFilterState={setFilterState}
        clearPagination={() =>
          setPagination({ ...pagination, limit: 20, offset: 0 })
        }
      />
    </div>
  )
}

export default ChangePhoneComponent

import { Moment } from "moment"

export enum FilterRangeFields {
  bets_sum = `bets_sum`,
  win_sum = `win_sum`,
  bonus_bets_sum = `bonus_bets_sum`,
  freebet_bets_sum = `freebet_bets_sum`,
  cash_in = `cash_in`,
  noncash_in = `noncash_in`,
  cash_out = `cash_out`,
  noncash_out = `noncash_out`,
  money_balance = `money_balance`,
  bonus_balance = `bonus_balance`,
  transfer_to = `transfer_to`,
  transfer_from = `transfer_from`
}

interface BetsFilters {
  [FilterRangeFields.bets_sum]: {
    enabled: boolean
    range?: {
      from?: number
      to?: number
    }
  }
  [FilterRangeFields.win_sum]: {
    enabled: boolean
    range?: {
      from?: number
      to?: number
    }
  }
  [FilterRangeFields.bonus_bets_sum]: {
    enabled: boolean
    range?: {
      from?: number
      to?: number
    }
  }
  [FilterRangeFields.freebet_bets_sum]: {
    enabled: boolean
    range?: {
      from?: number
      to?: number
    }
  }
  sportboom: boolean
  game_ids: number[]
}

interface CashboxFilters {
  [FilterRangeFields.cash_in]: {
    enabled: boolean
    range?: {
      from?: number
      to?: number
    }
  }
  [FilterRangeFields.noncash_in]: {
    enabled: boolean
    range?: {
      from?: number
      to?: number
    }
  }
  [FilterRangeFields.cash_out]: {
    enabled: boolean
    range?: {
      from?: number
      to?: number
    }
  }
  [FilterRangeFields.noncash_out]: {
    enabled: boolean
    range?: {
      from?: number
      to?: number
    }
  }
}

interface CurrentBalanceFilters {
  [FilterRangeFields.money_balance]: {
    enabled: boolean
    range?: {
      from?: number
      to?: number
    }
  }
  [FilterRangeFields.bonus_balance]: {
    enabled: boolean
    range?: {
      from?: number
      to?: number
    }
  }
}

interface TransferBalanceFilters {
  [FilterRangeFields.transfer_to]?: {
    enabled: boolean
    range?: {
      from?: number
      to?: number
    }
  }
  [FilterRangeFields.transfer_from]?: {
    enabled: boolean
    range?: {
      from?: number
      to?: number
    }
  }
}

export interface GuestFiltersData
  extends BetsFilters,
    CashboxFilters,
    CurrentBalanceFilters,
    TransferBalanceFilters {
  dates?: [Moment, Moment]
  gmt: string
  isShift: boolean
  period: string
  shift: [number, number]
}

export type GuestFiltersState = {
  isOpen: boolean
  mobileFilter: boolean
  isActive: boolean
  data: GuestFiltersData
}

import React, { useCallback, useEffect, useMemo, useState } from "react"
import { saveFilterInLocalStorage } from "api/helpers"
import { useRequestParams } from "api/hooks"

import { CheckboxValueType } from "antd/lib/checkbox/Group"

import { ReactComponent as CloseIcon } from "Components/icons/close_modal.svg"
import { ButtonPrimary } from "Components/Button"
import StatusesCheckBoxFilter from "./Components/StatusesCheckBoxFilter"
import RangePickerFilter from "./Components/RangePickerFilter"
import SearchInputFilter from "./Components/SearchInputFilter"
import GamesList from "./Components/Games"
import ShiftFilter from "./Components/Shift"

import {
  Filters,
  SideFilterData,
  SideFilterDates,
  SideFilterProps,
  SideFilterState,
  Statuses
} from "./types"
import { defaultFilterText } from "./helpers"
import { getAllStatusesIds } from "./Components/StatusesCheckBoxFilter/helpers"
import { HallListFilter } from "../HallPicker/HallPickerModal"
import { InputMaskComponent } from "../Input"
import { noop } from "lodash"
import InfoPanelComponent from "../FilterInfoPanel"

export const SideFilter: React.FC<SideFilterProps> = ({
  disabledHalls,
  additionClearDeps,
  statusesDict,
  filterName,
  filterText,
  filterState,
  initialForClear,
  setFilterState,
  resetPagination,
  filters,
  infoPanelConfig,
  filterConfig,
  filterLabels,
  CustomInfoPanelHalls,
  AdditionalCustomComponent,
  CustomComponent,
  getDisabled,
  disabledRangeDates,
  customFilterKeys,
  isLoading,
  periodOptions
}) => {
  const { current_hall } = useRequestParams()
  const [filter, setFilter] = useState<SideFilterData>(filterState.data)
  const mobileFilterOpen = window.innerWidth < 921

  useEffect(() => {
    setFilter(filterState.data)
  }, [filterState.data])

  const disableApply = useMemo((): boolean | undefined => {
    return getDisabled && getDisabled(filter)
  }, [filter, getDisabled])

  const hallToString = useMemo(
    () => (filter.halls || []).map((item: number) => `${item}`),
    [filter.halls]
  )

  const setFilterStatusesCheckbox = useCallback(
    (value: Statuses) => {
      setFilter(prev => ({ ...prev, statuses: value }))
    },
    [filter.statuses]
  )

  const setFilterRangePicker = useCallback(
    (value?: SideFilterDates) => {
      const key = customFilterKeys
        ? customFilterKeys[Filters.rangePicker]
        : `dates`
      setFilter(prev => ({ ...prev, [key as string]: value }))
    },
    [filter.dates]
  )

  const setSearchInput = useCallback(
    value => {
      if (
        //check if there is validator and if it is use it
        (filterConfig?.searchFilterValidator &&
          filterConfig.searchFilterValidator(value)) ||
        !filterConfig?.searchFilterValidator
      ) {
        setFilter(prev => ({
          ...prev,
          [customFilterKeys?.SearchInput ||
          "search"]: filterConfig?.searchNumber
            ? Number(value)
              ? Number(value)
              : undefined
            : value
        }))
      }
    },
    [
      filter.search,
      customFilterKeys?.SearchInput,
      filterConfig?.searchNumber,
      filterConfig?.searchFilterValidator
    ]
  )

  const setFilterGames = useCallback(
    value => {
      setFilter(prev => ({ ...prev, games: value }))
    },
    [filter.games]
  )

  const phoneLabel =
    filterLabels && filterLabels[Filters.phoneInput]
      ? filterLabels[Filters.phoneInput]
      : defaultFilterText.labels[Filters.phoneInput]

  const rangeLabel =
    filterLabels && filterLabels[Filters.rangePicker]
      ? filterLabels[Filters.rangePicker]
      : defaultFilterText.labels[Filters.rangePicker]

  const gamesLabels =
    filterLabels && filterLabels[Filters.gamesPicker]
      ? filterLabels[Filters.gamesPicker]
      : defaultFilterText.labels[Filters.gamesPicker]

  const shiftLabels =
    filterLabels && filterLabels[Filters.shiftSwitch]
      ? filterLabels[Filters.shiftSwitch]
      : defaultFilterText.labels[Filters.shiftSwitch]

  const resetFilter = () => {
    if (!!initialForClear) {
      setFilter(initialForClear)
    } else {
      setFilter(prev => ({
        ...prev,
        dates: undefined,
        search: undefined,
        statuses: !statusesDict ? getAllStatusesIds(statusesDict) : [],
        halls: []
      }))
    }
  }

  const showResetButton =
    !!initialForClear &&
    (filter?.dates ||
      filter?.search ||
      filter?.phone ||
      filter?.search ||
      statusesDict?.length !== filter?.statuses?.length ||
      (!!additionClearDeps && additionClearDeps(filter)))

  return (
    <div
      className={
        filterState.mobileFilter && mobileFilterOpen
          ? `MainFilterMobile`
          : `SideFilterComponentWrapper`
      }
    >
      <div
        className="MainFilterMobileClose"
        onClick={() => {
          setFilterState({
            ...filterState,
            mobileFilter: false
          })
        }}
      >
        <CloseIcon />
      </div>
      <div className="MainFilterHeader">
        {filterText?.header || defaultFilterText.header}
        {showResetButton && (
          <span className="SideFilterReset" onClick={resetFilter}>
            {filterText?.reset || defaultFilterText.reset}
          </span>
        )}
      </div>
      <div className="MainFilterContent">
        {filters.map(cmpPath => {
          switch (cmpPath) {
            case Filters.statusesCheckBox:
              return !!filter.statuses && !!statusesDict ? (
                <StatusesCheckBoxFilter
                  statuses={filter.statuses}
                  statusesDict={statusesDict || []}
                  text={filterText?.statusesCheckBoxFilter}
                  onChange={setFilterStatusesCheckbox}
                />
              ) : (
                <div />
              )
            case Filters.rangePicker:
              return (
                <RangePickerFilter
                  dates={filter.dates}
                  onChange={setFilterRangePicker}
                  text={{ header: rangeLabel }}
                  allowClear={!!filterConfig?.clearRange}
                  disableDates={disabledRangeDates}
                />
              )
            case Filters.searchInput:
              return (
                <SearchInputFilter
                  onChange={setSearchInput}
                  value={
                    (filter[
                      customFilterKeys?.SearchInput || "search"
                    ] as string) || ""
                  }
                  type={filterConfig?.searchNumber ? "number" : "text"}
                  text={filterText?.searchInputFilter}
                  searchAsObj={filterState?.searchAsObj}
                />
              )
            case Filters.hallPicker:
              return (
                <HallListFilter
                  current_halls={hallToString}
                  setCurrentHalls={(value: CheckboxValueType[]) => {
                    const hallValue: number[] = value.map(item => Number(item))
                    if (!value.length) {
                      setFilter({
                        ...filter,
                        halls: hallValue
                      })
                    } else {
                      setFilter({
                        ...filter,
                        halls: hallValue
                      })
                    }
                  }}
                />
              )
            case Filters.hallPickerRadio:
              return (
                <HallListFilter
                  current_halls={hallToString}
                  setCurrentHalls={([]) => noop}
                  radio={{
                    value: !!filter.halls?.length
                      ? Number(filter.halls[0])
                      : Number(current_hall[0]),
                    setValue: value => setFilter({ ...filter, halls: [value] })
                  }}
                />
              )
            case Filters.phoneInput:
              return (
                <div className="SideFilterField">
                  <div className="SideFilterContentLabel">{phoneLabel}</div>
                  <div className="InputWrapper ProposalModalPhoneInput">
                    <InputMaskComponent
                      mask={"+7 000 000-00-00"}
                      value={filter.phone}
                      allowClear={true}
                      onChange={x => {
                        setFilter({ ...filter, phone: `7${x.unmaskedValue}` })
                      }}
                    />
                  </div>
                </div>
              )
            case Filters.gamesSportPicker:
            case Filters.gamesPicker:
              return (
                <div className="SideFilterField">
                  <div className="SideFilterContentLabel">{gamesLabels}</div>
                  <div className="InputWrapper ProposalModalPhoneInput">
                    <GamesList
                      filterGames={filter.games || []}
                      setFilterGames={setFilterGames}
                      withSport={!!Filters.gamesSportPicker}
                    />
                  </div>
                </div>
              )
            case Filters.shiftSwitch:
              return (
                <div className="SideFilterField">
                  <div className="InputWrapper ProposalModalPhoneInput">
                    <ShiftFilter
                      filterState={filter}
                      setFilterState={setFilter}
                      label={shiftLabels}
                    />
                  </div>
                </div>
              )
            case Filters.InfoPanel:
              return (
                <div>
                  <InfoPanelComponent<SideFilterData>
                    disabledHalls={disabledHalls}
                    filter={filter}
                    filterHalls={filter?.halls}
                    setFilter={setFilter}
                    current_hall={current_hall}
                    disabledShift={infoPanelConfig?.disabledShift}
                    alwaysMoscow={infoPanelConfig?.alwaysMoscow}
                    withoutGmt={infoPanelConfig?.withoutGmt}
                    withoutPickDateType={infoPanelConfig?.withoutPeriod}
                    periodOptions={
                      periodOptions || defaultFilterText.infoPanel.periodOptions
                    }
                    disabledRangeDates={disabledRangeDates}
                    clearRange={infoPanelConfig?.clearRange}
                    gmtSelect={infoPanelConfig?.gmtSelect}
                    shiftSlider={infoPanelConfig?.shiftSlider}
                    CustomInfoPanelHalls={CustomInfoPanelHalls}
                  />
                </div>
              )
            case Filters.addCustomItem: {
              return !!AdditionalCustomComponent ? (
                <AdditionalCustomComponent
                  filter={filter}
                  setFilter={setFilter}
                  forceApply={(outsideFilter?: SideFilterState | any) => {
                    setFilterState({
                      ...filterState,
                      data: outsideFilter || filter,
                      isActive: true,
                      mobileFilter: false
                    })
                  }}
                />
              ) : (
                <div />
              )
            }
            case Filters.customItem: {
              return !!CustomComponent ? (
                <CustomComponent
                  filter={filter}
                  setFilter={setFilter}
                  forceApply={(outsideFilter?: SideFilterState | any) => {
                    setFilterState({
                      ...filterState,
                      data: outsideFilter || filter,
                      isActive: true,
                      mobileFilter: false
                    })
                  }}
                />
              ) : (
                <div />
              )
            }
            default:
              return
          }
        })}
      </div>

      <div className="MainFilterApplyWrapper">
        <ButtonPrimary
          disabled={disableApply}
          onClick={() => {
            resetPagination()
            setFilterState({
              ...filterState,
              data: filter,
              isActive: true,
              mobileFilter: false
            })
            saveFilterInLocalStorage(filter, filterName)
          }}
          loading={isLoading}
        >
          {filterText?.applyButton || defaultFilterText.applyButton}
        </ButtonPrimary>
      </div>
    </div>
  )
}

import React, { useCallback } from "react"

import { getCurrencyLayout, valueToCurrency } from "constants/index"

import { ReactComponent as InfoIcon } from "Components/icons/help.svg"
import TooltipComponent from "Components/Tooltip"

import { DashboardComponentProps, DashboardComponentValue } from "./types"
import { isNull } from "lodash"
import SpinComponent from "../Spin"

const moreText = `Подробнее`

const formatValueWithSpaces = (value: DashboardComponentValue) => {
  return isNull(value)
    ? ``
    : valueToCurrency({
        value: Number(value),
        withoutFixed: true
      })
}

const DashboardComponent = ({
  currencyDeps,
  config,
  loading
}: DashboardComponentProps) => {
  const getCurrency = useCallback(getCurrencyLayout, [currencyDeps])
  return (
    <div className="ComponentDashboardWrapper">
      {config.map((item, idx) => {
        const currencyValue = item.isCurrency
          ? getCurrency(item.value)
          : formatValueWithSpaces(item.value)
        return (
          <div
            key={idx}
            className={`ComponentDashboardItem ${
              !!item.onClick ? `ComponentDashboardItemClickable` : ``
            }`}
            style={{ backgroundColor: item.background }}
            onClick={item.onClick}
          >
            <div className="DashboardItemLabelContent">
              <div className="DashboardItemIconWrapper">{item.icon}</div>
              <span
                className="DashboardItemLabel"
                style={{ width: item.customWidth }}
              >
                {item.title}
              </span>
              <div className="DashboardItemInfoWrapper">
                <TooltipComponent
                  direction={item.tooltipDirection ?? "bottom"}
                  title={item.tooltipText}
                >
                  <InfoIcon />
                </TooltipComponent>
              </div>
            </div>
            <div className="FlexRow">
              {loading ? (
                <SpinComponent />
              ) : (
                <span className="DashboardItemLabelValue">
                  {item.isCurrency && !item.isString
                    ? currencyValue
                    : item.value}
                </span>
              )}
              {!!item.moreToggle && (
                <div
                  className="DashboardItemMoreButton ChartDecodingText"
                  onClick={item.moreToggle}
                >
                  {moreText}
                </div>
              )}
            </div>
            {item.description && (
              <span className="DashboardItemDescription">
                {item.description}
              </span>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default DashboardComponent

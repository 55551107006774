import React, { useEffect, useMemo, useState } from "react"

import { parseDateByShift, parseDateToBack } from "constants/index"

import {
  useRequest,
  useRequestBody,
  UseRequestBodyPagination,
  useRequestParams
} from "api/hooks"

import TableComponent from "Components/TableNew"
import EmptyTable from "Components/EmptyTable"

import { tableText } from "./constants"
import { TableData } from "./types"

import { FilterRangeFields, GuestFiltersData } from "../types"
import { getColumns } from "./helper"

const FiltersTable = ({
  filter,
  setTotal,
  pagination,
  setPagination
}: {
  filter: GuestFiltersData
  setTotal: (val: number) => void
  pagination: UseRequestBodyPagination
  setPagination: (val: UseRequestBodyPagination) => void
}) => {
  const { current_hall_number } = useRequestParams()
  const { sort, setSort } = useRequestBody()

  const [table, setTable] = useState<TableData>(tableText.initialData)

  const dates = filter.dates
    ? parseDateToBack({
        date: parseDateByShift({
          dates: filter.dates,
          isShift: filter.isShift,
          shift: filter.shift
        }),
        isObj: true,
        isFromTo: true,
        gmt: filter.gmt
      })
    : null

  const { request: getData } = useRequest({
    url: tableText.url,
    requestBody: {
      filter: {
        ...filter,
        dates,
        hall: current_hall_number[0],
        [FilterRangeFields.bonus_bets_sum]: filter.sportboom
          ? undefined
          : filter[FilterRangeFields.bonus_bets_sum],
        [FilterRangeFields.freebet_bets_sum]: filter.sportboom
          ? filter[FilterRangeFields.freebet_bets_sum]
          : undefined
      },
      sort: sort?.order ? sort : undefined,
      pagination
    }
  })
  const { columns, totalWidth } = useMemo(
    () => getColumns({ sort, setSort, filter }),
    [sort, filter]
  )

  useEffect(() => {
    setTable({ ...table, pending: true })
    getData()
      .then(({ data }) => {
        setTable({ data: data.items, total: data.total_count, pending: false })
        setTotal(data.total_count)
      })
      .catch(() => {
        setTable({ ...table, pending: false })
      })
  }, [sort, pagination, filter])

  return (
    <div>
      {!!table.total || table.pending ? (
        <TableComponent
          width={totalWidth}
          columns={columns}
          data={table.data}
          loading={table.pending}
          pagination={{
            total: table.total,
            value: pagination,
            onChange: setPagination
          }}
        />
      ) : (
        <EmptyTable />
      )}
    </div>
  )
}

export default FiltersTable

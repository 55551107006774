import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { Moment } from "moment"

export type MainPageDataFilterType = {
  isOpen: boolean
  data: {
    halls: (string | CheckboxValueType)[]
    shift: number
    gmt: string
  }
}

export type MainFilterData = {
  gmt: string
  shift: number
  halls: (number | CheckboxValueType)[]
}

export enum DashboardSocketEvents {
  Attendance = "attendance",
  Payments = "payments",
  Balance = "balance",
  New_guests = "new_guests",
  Qiwi = "balances",
  Error = `error`
}

export interface DashboardDetail {
  value: number
  hall: number
}

export interface DashboardStateDetailsSuperWin {
  date: string
  game_id: number
  hall: number
  hall_zone: string
  nominal: number
  win_sum: number
}

export type DashboardDetails = DashboardDetail[]
export type DashboardDetailsSuperWins = DashboardStateDetailsSuperWin[]

export interface DashboardItem {
  data: {
    value: number
    imageLink?: string
    date?: Moment
    color?: string
    details: DashboardDetails | DashboardDetailsSuperWins
  }
  pending: boolean
  isVisible: boolean
  rendered: boolean
}

export interface DashboardQiwiData {
  data: DashboardDetails
  title: string
  pending: boolean
  color: string
}

export interface StaticDashboardData {
  title: string
  pending: boolean
  data: DashboardDetailsSuperWins
  columns: any[]
  selectedHalls: CheckboxValueType[]
  onRedirect?: () => void
}

export type DashboardSocketMessage = [
  {
    type: DashboardSocketEvents
    detail: [{ hall: number; value: number }]
    value: number
  }
]

export enum DashboardColors {
  Attendance = "#E7F5FF",
  Payment = "#E6FCF5",
  NewGuest = "#F3F0FF",
  PaymentOut = "#FFF4E8",
  SuperWinsStars = "#FFF9DB"
}

export type DashboardSocket = WebSocket

export interface UseStaticDashboardApi {
  halls: CheckboxValueType[]
  gmt: string
}

export enum SuperWinsTableColumnNames {
  date = "date",
  game_id = "game_id",
  hall = "hall",
  hall_zone = "hall_zone",
  nominal = "nominal",
  win_sum = "win_sum"
}

export enum SuperWinsZones {
  vip = "vip",
  main = "main"
}

import React from "react"

import ModalComponent from "Components/Modal"
import { QiwiModalComponentProps } from "./types"

const QiwiModalComponent: React.FC<QiwiModalComponentProps> = ({
  data,
  close,
  name,
  title,
  color
}) => {
  return (
    <ModalComponent close={close} width={448}>
      <div className="QiwiModalWrapper">
        <div className="QiwiModalHeader GridCenter">Информация по клубам</div>
        <div className="QiwiModalTableWrapper">
          <div
            style={{ backgroundColor: color || `#E6F6F8` }}
            className="QiwiModalTableHeader FlexRow"
          >
            <div className="QiwiModalTableHeaderItem">№ Клуба</div>
            <div className="QiwiModalTableHeaderItem">{title}</div>
          </div>
          <div className="QiwiModalTableBody">
            {data.map((item, key) => (
              <div
                key={`qiwi_balance_hall_${item.hall}`}
                className={`QiwiModalTableRow FlexRow ${
                  data.length === key + 1 ? `QiwiModalTableLastRow` : ``
                }`}
              >
                <div className="QiwiModalTableRowItem">{item.hall}</div>
                <div className="QiwiModalTableRowItem">
                  {name ? item[name] : item.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ModalComponent>
  )
}

export default QiwiModalComponent

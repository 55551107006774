import React from "react"

import walletImg from "../icons/wallet.png"
import rubImg from "../icons/rub.png"
import bonusImg from "../icons/bonus.png"
import kassaImg from "../icons/kassa.png"

import { CashboxState } from "../../types"
import cashboxConstants from "../../constants"

export const getDashboardConfig = (
  cashboxState: CashboxState,
  toggleQiwiModal: (val: boolean) => void
) => {
  return [
    {
      background: cashboxConstants.dashboards.qiwi_balance.backgroundColor,
      title: cashboxConstants.dashboards.qiwi_balance.title,
      icon: (
        <img
          loading="lazy"
          className="DashboardItemIcon"
          alt={cashboxConstants.dashboards.qiwi_balance.title}
          src={walletImg}
        />
      ),
      onClick: () => toggleQiwiModal(true),
      value: cashboxState.data.qiwi_balance,
      isCurrency: true,
      tooltipText: cashboxConstants.dashboards.qiwi_balance.tooltip
    },
    {
      background: cashboxConstants.dashboards.money_balance.backgroundColor,
      title: cashboxConstants.dashboards.money_balance.title,
      icon: (
        <img
          loading="lazy"
          className="DashboardItemIcon"
          alt={cashboxConstants.dashboards.money_balance.title}
          src={rubImg}
        />
      ),
      value: cashboxState.data.money_balance,
      isCurrency: true,
      tooltipText: cashboxConstants.dashboards.money_balance.tooltip
    },
    {
      background: cashboxConstants.dashboards.bonus_balance.backgroundColor,
      title: cashboxConstants.dashboards.bonus_balance.title,
      icon: (
        <img
          loading="lazy"
          className="DashboardItemIcon"
          alt={cashboxConstants.dashboards.bonus_balance.title}
          src={bonusImg}
        />
      ),
      value: cashboxState.data.bonus_balance,
      isCurrency: true,
      tooltipText: cashboxConstants.dashboards.bonus_balance.tooltip
    },
    {
      background: cashboxConstants.dashboards.cashbox_result.backgroundColor,
      title: cashboxConstants.dashboards.cashbox_result.title,
      icon: (
        <img
          loading="lazy"
          className="DashboardItemIcon"
          alt={cashboxConstants.dashboards.cashbox_result.title}
          src={kassaImg}
        />
      ),
      value: cashboxState.data.cashbox_result,
      isCurrency: true,
      tooltipText: cashboxConstants.dashboards.cashbox_result.tooltip
    }
  ]
}

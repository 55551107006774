import React, { useState, useEffect } from "react"

import { parseOneDateToBack } from "constants/index"
import { useRequest, useRequestParams } from "api/hooks"

import DashboardComponent from "Components/Dashboard"
import { DashboardComponentConfig } from "Components/Dashboard/types"

import {
  PromotionBonusesLegoConfig,
  PromotionBonusesLegoFilterData
} from "../types"

const SummaryLegoBonus = ({
  config,
  filter
}: {
  config: PromotionBonusesLegoConfig
  filter: PromotionBonusesLegoFilterData
}) => {
  const { currentHallAsNumber } = useRequestParams()
  const { request: getSummary } = useRequest({
    url: config.summaryUrl,
    requestBody: {
      filter: {
        hall: currentHallAsNumber,
        dates: !!filter.dates
          ? {
              from: parseOneDateToBack({ date: filter.dates[0] }),
              to: parseOneDateToBack({ date: filter.dates[1] })
            }
          : undefined
      }
    }
  })

  const [summaryData, setSummaryData] = useState<{
    pending: boolean
    data: DashboardComponentConfig[]
  }>({
    pending: false,
    data: []
  })
  useEffect(() => {
    setSummaryData({ ...summaryData, pending: true })
    getSummary()
      .then(({ data }) => {
        if (data) {
          const lastIndex = config.summary.length
            ? config.summary.length - 1
            : 0
          const newSummary: DashboardComponentConfig[] = config.summary.map(
            (item, i) => {
              return {
                background: item.background,
                isCurrency: item.isCurrency,
                value: data[item.field],
                title: item.title,
                tooltipText: item.tooltipText,
                icon: item.icon,
                tooltipDirection:
                  lastIndex === i && window.screen.width < 620
                    ? "left"
                    : "bottom"
              }
            }
          )

          setSummaryData({ data: newSummary, pending: false })
        }
      })
      .catch(() => setSummaryData({ ...summaryData, pending: false }))
  }, [filter.dates, filter.hall])

  const hasDashboardsValue =
    summaryData.data
      .map(item => item.value)
      .reduce((a: any, b: any) => a + b, 0) !== 0

  return hasDashboardsValue ? (
    <DashboardComponent config={summaryData.data} />
  ) : (
    <div />
  )
}

export default SummaryLegoBonus

import React from "react"

import moment from "moment"

import { getDateByDigital } from "constants/index"
import { GetDateByDigitalTypes } from "constants/type"

import { DashboardComponentConfig } from "Components/Dashboard/types"

import { GuestsHistoryReportData } from "../types"
import moneyImg from "../img/dashboard_money.png"
import bonusImg from "../img/dashboard_bonus.png"
import freebetImg from "../img/dashboard_freebet.png"

export const GuestHistoryCardText = (
  data: GuestsHistoryReportData,
  open: () => void
) => {
  const format = `DD.MM.YYYY`

  const dashboardConfig: DashboardComponentConfig[] = [
    {
      background: `#F3F0FF`,
      title: `Денежный баланс, ₽`,
      icon: (
        <img
          loading="lazy"
          className="DashboardItemIcon"
          alt="Денежный баланс"
          src={moneyImg}
        />
      ),
      value: data.dashboards.money,
      isCurrency: true,
      tooltipText: `Денежный баланс гостя в данном клубе на текущий момент.`
    },
    {
      background: `#E7F5FF`,
      title: `Бонусный баланс, Б`,
      icon: (
        <img
          loading="lazy"
          className="DashboardItemIcon"
          alt="Бонусный баланс"
          src={bonusImg}
        />
      ),
      value: data.dashboards.bonus,
      isCurrency: true,
      tooltipText: `Бонусный баланс гостя в данном клубе на текущий момент.`
    },
    {
      background: `#FFF9DB`,
      title: `Количество \nфрибетов`,
      icon: (
        <img
          loading="lazy"
          className="DashboardItemIcon"
          alt="Количество фрибетов"
          src={freebetImg}
        />
      ),
      customWidth: `136px`,
      value: data.dashboards.freebets?.length,
      isCurrency: false,
      tooltipText: `Количество фрибетов гостя в данном клубе на текущий момент. При клике на кнопку "Подробнее" откроется информация об общей сумме фрибетов, сумме и дате окончания каждого фрибета, а также об условии использовании фрибета - тип ставки, мин. и макс. коэффициент.`,
      moreToggle: open
    }
  ]

  const calculateBetweenText = (isLast: boolean) => {
    const startDate = moment(data.firstVisit, `DD.MM.YYYY`)
    const endDate = moment(data.lastVisit, `DD.MM.YYYY`)

    const lastDate = {
      day: moment().diff(endDate, `days`),
      month: moment().diff(endDate, `months`),
      year: moment().diff(endDate, `years`)
    }

    const together = {
      day: moment().diff(startDate, `days`),
      month: moment().diff(startDate, `months`),
      year: moment().diff(startDate, `years`)
    }

    const diffObj = isLast ? lastDate : together

    const yearsDiff = diffObj.year
    let daysDiff = diffObj.day
    if (diffObj.day > 30) {
      const valueOfStart = moment(data.firstVisit, `DD.MM.YYYY`).valueOf()
      const valueOfEnd = moment(data.lastVisit, `DD.MM.YYYY`).valueOf()
      const monthDiffVal = diffObj.month - diffObj.year * 12
      const startDay = moment(valueOfStart)
        .add(diffObj.year, `years`)
        .add(monthDiffVal, `months`)

      const endDay = moment(valueOfEnd)
        .add(diffObj.year, `years`)
        .add(monthDiffVal, `months`)

      if (!isLast) {
        const diffVal = moment().diff(startDay, `d`)
        daysDiff = diffVal > 0 ? diffVal : startDay.diff(moment(), `d`)
      } else {
        const diffVal = moment().diff(endDay, `d`)
        daysDiff = diffVal > 0 ? diffVal : endDay.diff(moment(), `d`)
      }
    }
    const monthDiff = diffObj.month > 12 ? 11 : diffObj.month

    const yearsWord = getDateByDigital({
      value: yearsDiff,
      type: GetDateByDigitalTypes.year
    })

    const monthWord = getDateByDigital({
      value: monthDiff,
      type: GetDateByDigitalTypes.month
    })

    const daysWord = getDateByDigital({
      value: daysDiff,
      type: GetDateByDigitalTypes.days
    })

    const years = yearsDiff > 0 ? yearsWord : ``
    const month = monthDiff > 0 ? monthWord : ``
    const days =
      daysDiff || (!daysDiff && !monthDiff && !yearsDiff) ? daysWord : ``

    const isFull = !!years && !!month && !!days

    return isFull ? (
      <div className={`${isFull ? `FlexColumn` : `FlexRow`}`}>
        <div className="FlexRow">
          <span className="HeadlineThirdText">{years}</span>
          <span className="CalculateBetweenTextDate HeadlineThirdText">
            {month}
          </span>
        </div>
        <span className="HeadlineThirdText">{days}</span>
      </div>
    ) : (
      `${years} ${month} ${days}`
    )
  }

  const registration_dttm = moment(
    data.wallet.registration_dttm,
    `DD.MM.YYYY HH:mm:ss`
  ).format(`DD.MM.YYYY в HH:mm`)

  return {
    wallet: (
      <div className="FlexColumn">
        <span>
          {`Кошелек № ${
            data.wallet.id
          } · ${`Регистрация ${registration_dttm} ·`} ${
            data.region ? `${data.region} ·` : ``
          } ${data.city ? `г. ${data.city} ·` : ``}`}
        </span>
        <span>{data.birthday ? `День рождения ${data.birthday}` : ``}</span>
      </div>
    ),
    format,
    visitTitle: `История посещений`,
    visitTitleTooltip: `Посещением гостя является момент совершения ставки. Первое посещение - дата первой совершенной ставки, последнее посещение - дата последней совершенной ставки гостем. Время вместе - период с первой до последней совершенной ставки. Не был в клубе - период с момента совершения последней ставки по текущий момент`,
    lastVisit: `Последнее посещение`,
    lastVisitValue: moment(data.lastVisit, format).format(format),
    firstVisit: `Первое посещение`,
    firstVisitValue: moment(data.firstVisit, format).format(format),
    countTogether: calculateBetweenText(false),
    together: `Время вместе`,
    countNotVisit: calculateBetweenText(true),
    notVisit: `Не был в клубе`,
    dashboards: dashboardConfig,
    walletStatus: {
      label: data.wallet.active ? `Кошелек активен` : `Кошелек неактивен`,
      direction: `right`
    }
  }
}

import React, { useCallback, useMemo } from "react"
import { ReactComponent as InfoIcon } from "Components/icons/help.svg"
import SpinComponent from "Components/Spin"
import TooltipComponent from "Components/Tooltip"
import { DashboardItemProps } from "./types"
import { Skeleton } from "antd"
import { formatDateDefault, getCurrencyLayout } from "constants/index"
import { DashboardDetails } from "../types"
import mainConstants from "../constants"

const DashboardItemComponent: React.FC<DashboardItemProps> = ({
  itemValue,
  title,
  tooltip,
  color,
  onItemClick,
  icon,
  isMoney,
  hideMoney,
  showDetailsButton,
  hideDecimal
}) => {
  const isClickable = useMemo(() => !!itemValue.data.details?.length, [
    itemValue.data.details
  ])
  const handleClick = useCallback(() => {
    if (isClickable) {
      onItemClick({
        data: itemValue.data.details as DashboardDetails,
        title,
        color,
        pending: false
      })
    }
  }, [isClickable, onItemClick, itemValue, title, color])

  if (!itemValue.pending && !itemValue.isVisible && itemValue.rendered) {
    return <></>
  }

  return itemValue.pending && !itemValue.rendered ? (
    <Skeleton
      className={"ComponentDashboardItem ComponentDashboardItemSkeleton"}
      paragraph={{ rows: 2 }}
      title={false}
      active
    />
  ) : (
    <div
      className={`ComponentDashboardItem ${
        isClickable ? `ComponentDashboardItemClickable` : ``
      }`}
      style={{ backgroundColor: color }}
      onClick={handleClick}
    >
      <div className="FlexRow">
        <div className="DashboardItemLabelPosition">
          <div className="DashboardItemIconWrapper">
            <img
              loading="lazy"
              className="DashboardItemIcon"
              alt={title}
              src={icon}
            />
          </div>
          <div className="DashboardItemTitleContainer">
            <span className="DashboardItemLabel">{title}</span>
            {itemValue.data.date && (
              <span className="DashboardItemLabel DashboardItemLabel__Date">
                {formatDateDefault(itemValue.data.date)}
              </span>
            )}
          </div>
        </div>
        <div className="DashboardItemInfoWrapper">
          <TooltipComponent direction="bottom" title={tooltip}>
            <InfoIcon />
          </TooltipComponent>
        </div>
      </div>
      <div>
        {itemValue.pending ? (
          <SpinComponent />
        ) : (
          <>
            {!hideMoney && (
              <span className={isMoney ? "" : "DashboardItemLabelValue"}>
                {isMoney
                  ? getCurrencyLayout(
                      itemValue.data.value.toFixed(2),
                      hideDecimal
                    )
                  : itemValue.data.value}
              </span>
            )}
            {showDetailsButton && (
              <div className="DashboardItemDetailsButton">
                {mainConstants.dashboards.details}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DashboardItemComponent

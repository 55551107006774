import React from "react"
import { RangePickerComponent } from "Components/DatePicker"
import { RangePickerFilterProps } from "./types"
import { defaultRangePickerFilterText } from "./helpers"
import moment, { Moment } from "moment"

export const RangePickerFilter: React.FC<RangePickerFilterProps> = ({
  text,
  dates,
  onChange,
  dateRender,
  disabled,
  allowClear,
  disableDates
}) => {
  const disabledDateDefault = (current: Moment) =>
    current && current > moment().endOf(`day`)

  return (
    <div className="SideFilterField">
      <div className="SideFilterContentLabel">
        {text?.header || defaultRangePickerFilterText.header}
      </div>
      <RangePickerComponent
        value={dates}
        dateRender={dateRender}
        disabled={disabled}
        datesAsObject={true}
        disabledDate={disableDates || disabledDateDefault}
        allowClear={allowClear}
        onChange={(value?: [Moment, Moment]) => {
          if (!!value) {
            const start = moment(value[0]).startOf("day")
            const end = moment(value[1]).endOf("day")
            onChange(!!value ? { from: start, to: end } : undefined)
          } else {
            onChange(undefined)
          }
        }}
      />
    </div>
  )
}

import React, { useEffect, useState } from "react"
import { AxiosResponse } from "axios"

import { useRequest } from "api/hooks"
import TableComponent from "Components/TableNew"
import { EmptyData } from "Components/EmptyTable"
import newRegImg from "Components/icons/dashboard/new_registration.png"
import DashboardComponent from "Components/Dashboard"

import EmptyFilters from "./EmptyFilters"
import {
  getColumns,
  getCurrenHallFromFilter,
  textConstants
} from "../constants"
import { RegistrationReportTableProps, TableData, TableState } from "../types"
import SpinComponent from "../../../Components/Spin"

const RegistrationReportTable = ({
  filter,
  pagination,
  setPagination,
  setActive
}: RegistrationReportTableProps) => {
  const [tableData, setTableData] = useState<TableState>({
    pending: false,
    data: null
  })
  const currentHall = getCurrenHallFromFilter(filter)
  const columns = getColumns()
  const { request: getTableData } = useRequest({
    url: "v1/reports/registrations",
    method: "POST",
    requestBody: {
      filter: {
        hall: currentHall,
        date: filter.date
      },
      pagination
    }
  })

  useEffect(() => {
    if (filter.date) {
      setTableData({ ...tableData, pending: true })
      getTableData()
        .then((response: AxiosResponse<TableData>) => {
          const { data } = response
          setActive(!!data.items.length)
          setTableData({
            pending: false,
            data
          })
        })
        .catch(() => setTableData({ ...tableData, pending: false }))
    }
  }, [filter.date, pagination, filter.halls])

  if (!filter.date) {
    return <EmptyFilters />
  }

  return (
    <div className="RegistrationTable">
      {!!tableData.data?.items.length && (
        <DashboardComponent
          config={[
            {
              background: textConstants.dashboard.color,
              title: textConstants.dashboard.title,
              icon: (
                <img
                  loading="lazy"
                  src={newRegImg}
                  alt={textConstants.dashboard.title}
                />
              ),
              value: tableData.data?.registrations_count ?? 0,
              isCurrency: false,
              tooltipText: textConstants.dashboardTooltip
            }
          ]}
        />
      )}
      {!!tableData.data?.items.length && !tableData.pending && (
        <TableComponent
          columns={columns}
          data={tableData.data?.items || []}
          loading={tableData.pending}
          pagination={{
            total: tableData.data.total_count,
            value: pagination,
            onChange: setPagination
          }}
        />
      )}
      {tableData.pending && (
        <div className="RegistrationTable__Loader">
          <SpinComponent color="yellow" />
        </div>
      )}
      {!tableData.data?.items.length && !tableData.pending && (
        <div className="EmptyDataWrapper">
          <EmptyData />
        </div>
      )}
    </div>
  )
}

export default RegistrationReportTable

import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useRequest } from "api/hooks"
import { formatToCurrency } from "constants/index"

import balanceImg from "./bonus.png"
import persentImg from "./persent.png"
import accumulatedImg from "./accumulated.png"
import crownImg from "./crown.png"

import dashboardTextConfig, { getConfig } from "./helper"

import TooltipComponent from "Components/Tooltip"
import SpinComponent from "Components/Spin"
import DashboardComponent from "Components/Dashboard"

import { ReactComponent as InfoIcon } from "Components/icons/help.svg"
import { ReactComponent as ReloadIcon } from "Components/icons/reload.svg"
import { ReactComponent as MaxIcon } from "Components/icons/success_small.svg"

import { LoyaltyGuestDashboardType, LoyaltyGuestFilterDataType } from "../types"

const StatusPromoteComponent = ({
  filter
}: {
  filter: LoyaltyGuestFilterDataType
}) => {
  const deps = [filter.hall, filter.phone]

  const [promote, setPromote] = useState<{
    pending: boolean
    value: undefined | null | number
  }>({ pending: false, value: undefined })

  useEffect(() => {
    setPromote({ ...promote, value: undefined })
  }, deps)

  const { request } = useRequest({
    url: `reports/guestinfo/nextlevel`,
    method: `POST`,
    requestBody: { hall: filter.hall, phone: `7${filter.phone}` }
  })
  const getPromote = useCallback(() => {
    setPromote({ ...promote, pending: true })
    request()
      .then(({ data: { nextLevel } }: { data: { nextLevel: number } }) =>
        setPromote({ pending: false, value: nextLevel })
      )
      .catch(() => setPromote({ ...promote, pending: false }))
  }, deps)
  return (
    <div
      className="ReportDashboardItemWrapper"
      style={{ backgroundColor: `#F4F4F4` }}
    >
      <div className="FlexRow">
        <div className="DashboardItemLabelPosition">
          <span className="StatusPromoteLabel" style={{ width: `164px` }}>
            {dashboardTextConfig.nextLevel.title}
          </span>
        </div>
        <div className="DashboardItemInfoWrapper">
          <TooltipComponent
            direction="bottom"
            title={dashboardTextConfig.nextLevel.description}
          >
            <InfoIcon />
          </TooltipComponent>
        </div>
      </div>
      <div className="ReportDashboardValue">
        {promote.pending ? (
          <SpinComponent />
        ) : (
          <div>
            {promote.value === undefined && (
              <div className="GuestInfoPromoteSpecify" onClick={getPromote}>
                Уточнить
              </div>
            )}
            {promote.value === null && (
              <div className="FlexRow">
                <div>
                  <MaxIcon />
                </div>
                <div className="GuestInfoPromoteMax">
                  {dashboardTextConfig.nextLevel.max}
                </div>
              </div>
            )}
            {typeof promote.value === `number` && (
              <div className="FlexRow">
                <div className="GuestInfoPromoteValue">
                  {formatToCurrency({ amount: promote.value })}
                </div>
                <div className="GuestInfoPromoteReload" onClick={getPromote}>
                  <ReloadIcon />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const GuestDashboard = ({
  data,
  filter
}: {
  data: LoyaltyGuestDashboardType
  filter: LoyaltyGuestFilterDataType
}) => {
  const dashboardConfig = useMemo(() => getConfig(data), [data])
  return (
    <div className="FlexRow GuestDashboardWrapper">
      <DashboardComponent config={dashboardConfig} />
      <StatusPromoteComponent filter={filter} />
    </div>
  )
}

export default GuestDashboard

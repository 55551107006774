import React, { useMemo, useState } from "react"

import { useSelector } from "react-redux"

import { getGamesList } from "store/dictionary/selector"

import { SwitchFilterComponent } from "Components/Switch"
import GamesList from "Components/SideFilter/Components/Games"
import { CheckBoxComponent } from "Components/CheckBox"
import TooltipComponent from "Components/Tooltip"

import { ReactComponent as SportboomInfoIcon } from "Components/icons/help_16.svg"

import { FilterProps } from "./index"
import { customFilterText } from "./constants"
import RangeComponent from "./RangeComponent"

import { FilterRangeFields } from "../types"
import { initialFilterBets } from "../constants"

const BetsFilter = ({ filter, setFilter }: FilterProps) => {
  const games = useSelector(getGamesList)

  const gameIds = useMemo(() => games.map(item => Number(item.id)), [])

  const [showBetsFilter, toggleBetsFilter] = useState(false)
  const betsTestBySportboom = filter.sportboom
    ? customFilterText.fieldLabels.sportboom
    : customFilterText.fieldLabels[FilterRangeFields.bonus_bets_sum]
  return (
    <div className={showBetsFilter ? `PaddingBottom24` : ``}>
      <SwitchFilterComponent
        checked={showBetsFilter}
        onChange={() => {
          const valueBySwitcher = initialFilterBets(!showBetsFilter)
          setFilter({
            ...filter,
            ...valueBySwitcher,
            game_ids: !showBetsFilter ? gameIds : []
          })
          toggleBetsFilter(!showBetsFilter)
        }}
        label={customFilterText.label.bets}
      />
      {showBetsFilter && (
        <div className="FlexColumn Gap12">
          <RangeComponent
            label={customFilterText.fieldLabels[FilterRangeFields.bets_sum]}
            field={FilterRangeFields.bets_sum}
            filter={filter}
            setFilter={setFilter}
          />
          <RangeComponent
            label={customFilterText.fieldLabels[FilterRangeFields.win_sum]}
            field={FilterRangeFields.win_sum}
            filter={filter}
            setFilter={setFilter}
          />
          <RangeComponent
            label={betsTestBySportboom}
            field={
              filter.sportboom
                ? FilterRangeFields.freebet_bets_sum
                : FilterRangeFields.bonus_bets_sum
            }
            filter={filter}
            setFilter={setFilter}
          />
          <div className="FlexColumn Gap8">
            <span className="SubtitleFirstText">{customFilterText.games}</span>
            <div className="PaddingBottom2 FlexRow Gap4">
              <CheckBoxComponent
                label={customFilterText.sportboom}
                checked={filter.sportboom}
                onChange={({ target: { checked } }) => {
                  setFilter({ ...filter, sportboom: checked, game_ids: [] })
                }}
              />
              <div className="PaddingTop4">
                <TooltipComponent title={customFilterText.sportboom_tooltip}>
                  <SportboomInfoIcon />
                </TooltipComponent>
              </div>
            </div>
            <GamesList
              filterGames={filter.game_ids}
              setFilterGames={games => {
                const newList = games as number[]
                setFilter({ ...filter, game_ids: newList, sportboom: false })
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default BetsFilter

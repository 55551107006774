import React from "react"
import { isString } from "lodash"
import InputComponent from "Components/Input"
import { Step3FieldProps } from "Containers/Sms/types"
import { smsConstants } from "Containers/Sms/constants"

const Bonus = ({ smsState, setSmsState }: Step3FieldProps) => {
  return (
    <div className="CreateSms_input CreateSms_inputShort">
      <label className="CreateSms_inputLabel">
        {smsConstants.step3.bonusTitle}
      </label>
      <div className="CreateSms_inputInner">
        <InputComponent
          error={
            isString(smsState.errors?.macros?.bonus)
              ? smsState.errors?.macros?.bonus
              : smsState.errors?.macros?.bonus?.from
          }
          greyBackground
          type="number"
          min={0}
          hideControls
          value={smsState.extraParams.bonusFrom ?? ""}
          onChange={({ target: { value } }) => {
            setSmsState({
              ...smsState,
              extraParams: {
                ...smsState.extraParams,
                bonusFrom: value
              }
            })
          }}
          placeholder={smsConstants.fromTextCapitalized}
        />
        <InputComponent
          greyBackground
          type="number"
          min={0}
          hideControls
          error={
            isString(smsState.errors?.macros?.bonus)
              ? smsState.errors?.macros?.bonus
              : smsState.errors?.macros?.bonus?.to
          }
          value={smsState.extraParams.bonusTo ?? ""}
          onChange={({ target: { value } }) => {
            setSmsState({
              ...smsState,
              extraParams: {
                ...smsState.extraParams,
                bonusTo: value
              }
            })
          }}
          placeholder={smsConstants.toTextCapitalized}
        />
      </div>
    </div>
  )
}
export default Bonus

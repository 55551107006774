import React, { Dispatch, SetStateAction } from "react"

import { Moment } from "moment"

import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { CustomComponentProps } from "../SideFilter/types"

export enum Durations {
  day = "day",
  month = "month",
  year = "year"
}

export enum FilterDurations {
  hourly = "hourly",
  daily = "daily",
  monthly = "monthly",
  yearly = "yearly"
}

export enum PickerDurations {
  hour = "hour",
  date = "date",
  month = "month",
  year = "year"
}

export enum PickerFormats {
  month = "MMMM",
  year = "YYYY",
  timeShort = "HH:mm",
  timeLong = "HH:mm:ss",
  dayMonthYear = "DD.MM.YYYY",
  dateShortFromBack = "YYYY-MM-DD",
  dateLongFromBack = "YYYY-MM-DDTHH:mm:ss",
  fullMonthYear = "MMMM YYYY",
  shortMonthYear = "MMM YYYY"
}

export enum PickerFormatsShortYear {
  dayMonth = "DD.MM",
  dayMonthYear = "DD.MM.YY"
}

export type PeriodOptionsData = {
  value: string
  label: string
}

export type InfoPanelComponentProps<F extends Record<string, unknown>> = {
  filter: any
  reportType?: JSX.Element
  disabledRangeDates?: (currentDate: Moment | null) => boolean
  setFilter: Dispatch<SetStateAction<F>>
  filterHalls?: (string | CheckboxValueType)[]
  current_hall: (string | CheckboxValueType)[]
  disabledShift?: boolean
  disabledHalls?: boolean
  disabledShiftSwitch?: boolean
  withoutPickDateType?: boolean
  alwaysMoscow?: boolean
  withoutShift?: boolean
  clearRange?: boolean
  gmtSelect?: boolean
  periodOptions?: PeriodOptionsData[]
  shiftSlider?: boolean
  periodOptionsTooltipText?: string
  withoutGmt?: boolean
  // Custom components
  CustomInfoPanelHalls?: React.FC<CustomComponentProps>
}

export type Picker = PickerDurations | undefined

import React, { useMemo, useState } from "react"
import { useSelector } from "react-redux"

import { isArray } from "lodash"

import { valueToCurrency } from "constants/index"

import { getGamesList } from "store/dictionary/selector"

import { UseRequestBodySort } from "api/hooks"

import { getColumnSort } from "Components/TableNew/helpers"
import { TableHeaderSort } from "Components/TableNew"

import { ReactComponent as BallIcon } from "Components/icons/gameIcons/football_ball_outline_20.svg"

import { baseFields, tableText } from "./constants"

import { FilterRangeFields, GuestFiltersData } from "../types"

export const getColumnActiveClass = (field: string | undefined, key: string) =>
  `FiltersTableColumn ${field === key ? `ActiveSortTableColumn` : ``}`

const GamesList = ({ game_ids }: { game_ids: number[] }) => {
  const isLongList = game_ids.length > 2
  const moreText = useMemo(() => {
    return tableText.table.games.more(isLongList ? game_ids.length - 2 : 0)
  }, [])
  const [showMore, toggleMore] = useState(false)

  const dictionaryGames = useSelector(getGamesList)

  const currentGames = dictionaryGames.filter(game => {
    const id = game.id as number
    return game_ids.includes(id)
  })

  const data = showMore ? currentGames : currentGames.slice(0, 2)

  return (
    <div className="FlexRow Gap8 FlexWrap">
      {data.map(item => (
        <div key={`game_${item.id}`} className="FlexRow Gap4">
          <img className="GuestFiltersTableGameImg" src={item.image} />
          <span>{item.name}</span>
        </div>
      ))}
      {isLongList && (
        <span
          className="LinkText HoverItem"
          onClick={() => toggleMore(!showMore)}
        >
          {showMore ? tableText.table.games.cancel : moreText}
        </span>
      )}
    </div>
  )
}

const SportboomColumn = () => (
  <div className="FlexRow Gap4">
    <BallIcon />
    <span className="TextOne">{tableText.table.games.sportboom}</span>
  </div>
)

const constructColumnsByFilter = (filter: GuestFiltersData) => {
  let columns = tableText.base_columns_arr
  Object.keys(FilterRangeFields).filter(item => {
    const field = item as FilterRangeFields
    if (!filter.sportboom && field === FilterRangeFields.freebet_bets_sum)
      return false
    if (filter.sportboom && field === FilterRangeFields.bonus_bets_sum)
      return false
    if (
      filter.sportboom &&
      field === FilterRangeFields.freebet_bets_sum &&
      filter[field]?.enabled
    ) {
      columns = [
        ...columns,
        {
          ...tableText[field],
          title: tableText[FilterRangeFields.bonus_bets_sum].sportboom_title,
          field
        }
      ]
    }
    if (
      filter[field]?.enabled &&
      field !== FilterRangeFields.freebet_bets_sum
    ) {
      columns = [...columns, tableText[field]]
    }
    if (
      (field === FilterRangeFields.bonus_bets_sum ||
        field === FilterRangeFields.freebet_bets_sum) &&
      (!!filter.game_ids?.length || filter.sportboom)
    ) {
      columns = [...columns, tableText.game_ids]
    }
    return filter[field]?.enabled
  })

  return columns
}

export const getColumns = ({
  sort,
  setSort,
  filter
}: {
  sort: UseRequestBodySort
  setSort: (val: UseRequestBodySort) => void
  filter: GuestFiltersData
}) => {
  let totalWidth = 0
  const columnsByFilter = constructColumnsByFilter(filter)
  const columns = columnsByFilter.map((item, index) => {
    const classByIndex =
      index === 0 ? `TableHeaderFirst TableHeader` : `TableHeader`
    const firstTdClass = index === 0 ? `PaddingLeft32 ` : ``
    const { field, width, title, withoutSort } = item
    const isSort =
      sort.field === field && !!sort.order ? getColumnSort(sort.order) : ``
    totalWidth = totalWidth + item.width + 32

    if (field === baseFields.name) {
      return {
        title: () => <div className={classByIndex}>{title}</div>,
        dataIndex: field,
        key: field,
        width,
        render: (value: string) => {
          const parts = value?.split(" ")

          const firstPart = parts?.slice(0, 2)?.join(" ")

          const secondPart = parts[2]
          return (
            <div className={`${firstTdClass} FlexColumn`}>
              <span className="TextOne">{firstPart}</span>
              <span className="TextOne">{secondPart}</span>
            </div>
          )
        }
      }
    }

    return withoutSort
      ? {
          title: () => <div className={classByIndex}>{title}</div>,
          dataIndex: field,
          key: field,
          width,
          render: (value: any) => {
            const isGames =
              (isArray(value) || filter.sportboom) && field !== baseFields.name
            if (isGames) {
              const isSportboom = filter.sportboom
              return isSportboom ? (
                <SportboomColumn />
              ) : (
                <GamesList game_ids={value} />
              )
            }
            return <span className={firstTdClass}>{value}</span>
          }
        }
      : {
          title: () => (
            <TableHeaderSort
              isSort={isSort}
              sort={sort}
              field={field}
              setSort={setSort}
              title={title}
              className={classByIndex}
            />
          ),
          dataIndex: field,
          key: field,
          width,
          fixed: item.fixed,
          render: (value: any) => {
            if (item.isCurrency) {
              const currency = valueToCurrency({
                value,
                withoutZero: true,
                withoutFixed: true
              })
              return <span className={firstTdClass}>{currency}</span>
            }
            return <span className={firstTdClass}>{value}</span>
          }
        }
  })

  return { columns, totalWidth }
}

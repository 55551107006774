/* eslint react/prop-types: "off" */
import React, { useState } from "react"

import moment, { Moment } from "moment"

import { DatePicker, TimePicker } from "antd"
import locale from "antd/es/date-picker/locale/ru_RU"
import "antd/dist/antd.css"

import { isMobile } from "constants/index"

import "moment/locale/ru"

import { ReactComponent as DatePickerIcon } from "./calendar_20.svg"
import { ReactComponent as TimeIcon } from "./time.svg"

import { DatePickerComponentProps, TimePickerComponentProps } from "./types"
import { useGreyDateInputBackground } from "./helpers"

import { Durations } from "Components/FilterInfoPanel/types"
import { FilterPeriod } from "Containers/Promotion/Bonuses/Lego/CustomFilter/constants"

const { RangePicker } = DatePicker

const dateField = (
  value: Moment | null | undefined | Date | string,
  checkOffset: boolean
) => {
  let resultDate = null

  if (moment.isMoment(value)) {
    resultDate = checkOffset ? value : value.utc(true)
  }

  return resultDate
}

export const TimePickerComponent = ({
  value,
  greyBackground,
  error = false,
  ...rest
}: TimePickerComponentProps) => {
  const [focused, setFocused] = useState(false)
  const { greyBg } = useGreyDateInputBackground({ value, greyBackground })
  return (
    <div
      className={`TimePickerComponentWrapper ${
        error ? `ErrorPickerWrapper` : ``
      } ${!focused && greyBg ? "DatePickerWrapperGreyBackground" : ""}`}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      <TimePicker
        format="HH:mm"
        showNow={false}
        allowClear={false}
        value={value}
        {...rest}
        suffixIcon={<TimeIcon />}
        popupClassName="TimePickerComponentPopupWrapper"
      />
      {!!error && <div className="ErrorText">{error}</div>}
    </div>
  )
}

const DatePickerComponent = ({
  value,
  error = false,
  showTime,
  onChange,
  style,
  allowClear,
  disabledDate,
  greyBackground,
  format,
  picker,
  checkOffset,
  ...rest
}: DatePickerComponentProps) => {
  const [focused, setFocused] = useState(false)
  const { greyBg } = useGreyDateInputBackground({ value, greyBackground })

  const disabledDateDefault = (current: Moment) => {
    return current && current > moment().endOf(Durations.day)
  }

  return (
    <div
      className={`DatePickerWrapper ${error ? `ErrorPickerWrapper` : ``} ${
        !focused && greyBg ? "DatePickerWrapperGreyBackground" : ""
      }`}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      <DatePicker
        value={dateField(value, !!checkOffset)}
        allowClear={allowClear}
        format={format || `DD.MM.YY`}
        picker={picker}
        locale={locale}
        disabledDate={disabledDate || disabledDateDefault}
        separator={<div className="DatePickerSeparatorWrapper">–</div>}
        showTime={showTime}
        onChange={onChange}
        suffixIcon={<DatePickerIcon />}
        {...style}
        {...rest}
      />
      {!!error && <div className="ErrorText">{error}</div>}
    </div>
  )
}

export type RangePickerPeriod =
  | "time"
  | "date"
  | "week"
  | "month"
  | "quarter"
  | "year"
  | undefined

interface IRangePickerComponent {
  autoFocus?: boolean
  onBlur?: () => void
  name?: string
  style?: any
  disabledDate?: any
  value?: [Moment, Moment] | null | { from: Moment; to: Moment }
  datesAsObject?: boolean
  picker?: RangePickerPeriod
  allowClear?: boolean
  error?: string | boolean
  format?: string
  showTime?: {
    format?: string
  }
  dateRender?: (currentDate: Moment) => void
  onChange?: (data: any) => void
  disabled?: boolean
  isMiddle?: boolean
  disableFutureDates?: boolean
}

export const RangePickerComponent = ({
  value,
  datesAsObject,
  error = false,
  allowClear,
  disabledDate,
  dateRender,
  picker,
  format,
  onChange,
  disabled,
  isMiddle,
  disableFutureDates,
  ...rest
}: IRangePickerComponent) => {
  let filterValue = value

  if (datesAsObject && value && typeof value === "object") {
    filterValue = Object.values(value) as [Moment, Moment]
  }

  const defaultFormat = isMobile ? `DD.MM.YY` : `DD.MM.YYYY`

  const disableFutureDatesHandler = (current: Moment) => {
    switch (picker) {
      case FilterPeriod.date:
        return current && current > moment().endOf(Durations.day)
      case FilterPeriod.month:
        return current && current > moment().endOf(Durations.month)
      default:
        return current && current > moment().endOf(Durations.day)
    }
  }

  return (
    <div
      className={`RangePickerWrapper ${
        error ? `ErrorRangePickerWrapper` : ``
      } ${isMiddle ? `RangePickerMiddleWrapper` : ``}`}
    >
      <RangePicker
        value={filterValue as any}
        dateRender={dateRender}
        disabledDate={
          disableFutureDates ? disableFutureDatesHandler : disabledDate
        }
        allowClear={allowClear}
        format={format || defaultFormat}
        picker={picker || FilterPeriod.date}
        style={{ width: `100%` }}
        locale={locale}
        placeholder={[`Начало`, `Конец`]}
        separator={<div className="DatePickerSeparatorWrapper">–</div>}
        onChange={changeData => {
          if (changeData) {
            const start = changeData[0] ? changeData[0].startOf(`day`) : null
            const end = changeData[1] ? changeData[1].endOf(`day`) : null
            onChange && onChange([start, end])
          } else onChange && onChange(changeData)
        }}
        suffixIcon={<DatePickerIcon />}
        disabled={disabled}
        {...rest}
      />
      {!!error && <div className="ErrorText">{error}</div>}
    </div>
  )
}

export default DatePickerComponent

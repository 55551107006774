import { UseRequestBodySort } from "api/hooks"
import React, { ChangeEvent, ReactElement } from "react"
import { GamesData } from "store/dictionary/types"
import { ColumnProps } from "antd/lib/table"

export enum NominalSectionKeys {
  main = "main",
  vip = "vip"
}

export enum NominalsMinMaxKeys {
  min = "min",
  max = "max"
}

export interface CreateHistoryColumnsArgs {
  onMoreClicked: (comment: string) => void
  onDatesChanged: (dates: { from: string; to: string }) => void
  onGamesChanged: (games: number[]) => void
  onSortChanged: (sort: UseRequestBodySort) => void
  gamesDict: GamesData[]
  filterDates: { from?: string; to?: string }
  filterGames: number[]
  sort: UseRequestBodySort
}

interface Nominal {
  id: number
  value: number
}

export interface NumberCountValue {
  number_count: number
  value: number
}

export interface MaxLimit {
  [NominalsMinMaxKeys.min]: NumberCountValue[]
  [NominalsMinMaxKeys.max]: NumberCountValue[]
}

export interface NominalSection {
  nominals: Nominal[]
  max_limit: MaxLimit | number
  limits?: MaxLimit
}

export interface NominalSectionNew {
  nominals: Nominal[]
  limits?: MaxLimit
  max_limit?: MaxLimit | number
}

export interface Template {
  nominals: Nominal[]
  limits?: MaxLimit
  max_limit?: MaxLimit | number
}

export interface NominalsGame {
  game_id: number
  [NominalSectionKeys.main]: NominalSection
  [NominalSectionKeys.vip]: NominalSection
  note: string
  templates: Template[]
}

export interface NominalsNewModal {
  [NominalSectionKeys.main]: NominalSectionNew
  [NominalSectionKeys.vip]: NominalSectionNew
}

export interface NominalsNew extends NominalsNewModal {
  mergeNeeded: boolean
}

export interface GameTabItemProps {
  label: string
  id: number
  icon: React.ReactNode
  key: number
  isActive: boolean
  onActiveGameIdClick: (id: number) => void
}

export interface ChangeTabColumn {
  title: string
  dataIndex: string
  key: string
  minWidth: number
  render: (val: any) => JSX.Element
}

interface NominalsNewRequest extends NominalsNew {
  game_id: number
  phone?: string
  name?: string
}

export interface NominalsRequests {
  historyArgs?: {
    game_ids: number[]
    dates: { from?: string; to?: string }
    sort: { field?: string; order?: string }
    limit: number
    offset: number
  }
  validateArgs?: NominalsNewRequest
  changeArgs?: NominalsNewRequest
}

export interface MinMaxElement {
  number_count: number
  value: number
}
export interface MinMax {
  min: MinMaxElement[]
  max: MinMaxElement[]
}

export interface TableRowId {
  title: string
  img: ReactElement
}

export interface TableRow {
  [key: string]:
    | string
    | number
    | MinMax
    | TableRowId
    | {
        [key: string]:
          | number
          | string
          | MinMax
          | TableRowId
          | NominalSectionKeys
      }
  id: string | TableRowId
}

export interface TableRowMinMaxCol {
  [key: string]: TableRowMinMax
}

export interface TableRowMinMax {
  zone: NominalSectionKeys
  minMaxKey: NominalsMinMaxKeys
  value: number
  numberCount: number
}

export interface TemplateCardProps {
  template: Template
  idx?: number
  isMinMax: boolean
  onTemplateActivated?: (data: TemplateDataState) => void
  isActive?: boolean
  isModalTemplate?: boolean
  sectionName?: NominalSectionKeys
  isVipCheckBoxDisabled?: boolean
}

export interface TemplateCardTableRowProps {
  limits: NumberCountValue[]
  title: string
}

export interface RequestModalProps {
  nominalsData?: NominalsNewModal
  isMinMax: boolean
  onClose: () => void
  onCloseSuccess: () => void
  onPhoneInputChange: (value: ChangeEvent<HTMLInputElement>) => void
  onNameInputChange: (value: ChangeEvent<HTMLInputElement>) => void
  onChangeSend: () => void
  showSuccessImg: boolean
  loading: boolean
  disabled: boolean
  hideVipTemplate?: boolean
  userName: string
}

export interface TemplateDataState {
  isActive: boolean
  id: number | null
  [NominalSectionKeys.vip]?: Template
  [NominalSectionKeys.main]?: Template
}

interface NominalError {
  id: number
  error: string
}

interface NominalLimitsError {
  [NominalsMinMaxKeys.min]: { error: string; number_count: number }[]
  [NominalsMinMaxKeys.max]: { error: string; number_count: number }[]
}

export interface NominalsErrors {
  [NominalSectionKeys.vip]?: {
    nominals: NominalError[]
    limits: NominalLimitsError
    max_limit?: string
  }
  [NominalSectionKeys.main]?: {
    nominals: NominalError[]
    limits: NominalLimitsError
    max_limit?: string
  }
}

export interface NominalsFilter {
  dates: { from?: string; to?: string }
  game_ids: number[]
}

export type NominalsKeys = "main" | "vip"

export interface BaseBetColumnsAndDataArgs {
  isMinMax: boolean
  nominalsNew: NominalsNew
  nominalsErrors: NominalsErrors
  setNominalsNew: React.Dispatch<React.SetStateAction<NominalsNew>>
}

export interface MakeBetColumnsAndDataArgs extends BaseBetColumnsAndDataArgs {
  activeGame: NominalsGame | null
}

export interface MakeBetColumnsAndDataArgsRow
  extends BaseBetColumnsAndDataArgs {
  maxLimit?: MinMax | number
  zone: NominalSectionKeys
}

export interface MobileTableCardProps {
  columns: ColumnProps<ChangeTabColumn>[]
  data: TableRow[]
  minMaxData?: TableRowMinMaxCol[]
  minMaxColumns?: any[]
  isMinMax?: boolean
}

export enum NominalHistoryStatus {
  PROCESSING = "processing",
  COMPLETED = "completed",
  CANCELED = "canceled"
}

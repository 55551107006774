import React from "react"

import walletImg from "Components/icons/dashboard/wallet.png"
import totalImg from "Components/icons/dashboard/rub.png"

type GetDashboardConfigProps = {
  value: string
  total: string
  toggleQiwiModal: (val: boolean) => void
  withTotal?: boolean
}

export const titleText = {
  qiwiTooltip:
    "Расчетный баланс клуба/клубов для безналичных выплат на банковские карты гостей на текущий момент.",
  balance: "Баланс для выплат, ₽",
  totalTooltip:
    "Общая сумма транзакций по клубу/ам за выбранный в фильтрах период.",
  total: "Общая \nсумма, ₽",
  getDashboardConfig: ({
    value,
    total,
    withTotal,
    toggleQiwiModal
  }: GetDashboardConfigProps) => {
    const balanceDashboard = [
      {
        title: titleText.balance,
        icon: (
          <img
            loading="lazy"
            className="DashboardItemIcon"
            alt={titleText.balance}
            src={walletImg}
          />
        ),
        background: `#FFF4E6`,
        value,
        isCurrency: true,
        tooltipText: titleText.qiwiTooltip,
        onClick: () => toggleQiwiModal(true)
      }
    ]
    return withTotal
      ? [
          ...balanceDashboard,
          {
            title: titleText.total,
            icon: (
              <img
                loading="lazy"
                className="DashboardItemIcon"
                alt={titleText.total}
                src={totalImg}
              />
            ),
            background: `#F3F0FF`,
            value: total,
            isCurrency: true,
            tooltipText: titleText.totalTooltip
          }
        ]
      : balanceDashboard
  }
}
